import React, { useRef, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {  Tab, Tabs } from "react-bootstrap";

import "./Candidate.css";

import {
  getJobsInfo, getAssignedUsers, getPanelsList, getCandidate,
  getCountries, getStates, getCities,
  getSkillFamilies, getAllSkills,getSkillsBySkillFamily,
  getCustomerCompanies, getVendors, getDeliveryUnits,
  updateCandidate, uploadCandidates, deleteDocument, getCandidateDocumentById,
  uploadCandidateDocument, updateSkillsForCandidate, getListOfTimezones
} from '../../service/api';

import { toast } from "react-toastify";

// import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const timeOptions = Array.from({ length: 48 }, (_, index) => {
  const hour = Math.floor(index / 2).toString().padStart(2, '0');
  const minute = (index % 2 === 0) ? '00' : '30';
  return { value: `${hour}:${minute}`, label: `${hour}:${minute}` };
});

export default function EditCandidate() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const { candidateId, customerId } = useParams();
  const [lpChecklistTabKey, setLPChecklistTabKey] = useState('home')
  const changeLPChecklistTab = (key) => {
      if (key !== lpChecklistTabKey) {
        setLPChecklistTabKey(key)
      }
  }
  const [jobs, setJobs] = useState([]);

  const [countries, setCountries] = useState([]);
  // const [states, setStates] = useState([]);
  // const [cities, setCities] = useState([]);
  const [skillFamilies, setSkillFamilies] = useState([]);
  const [customersData, setCustomers] = useState([]);
  const navigate = useNavigate();
  // const [skillFamilyData, setSkillFamiliesData] = useState([]);
  // const [skillFamilyDict, setSkillFamiliesDict] = useState({});
  const [candidateResume, setCandidateResume] = useState(null);
  const [candidateResumeName, setCandidateResumeName] = useState('Choose a file');
  const [users, setUsers] = useState([]);
  const [panels, setPanels] = useState([]);
  const [dynamicSkills, setDynamicSkills] = useState([]);
  const [standardSkills, setStandardSkills] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [uploadDocument, setUploadDocument] = useState(null);
  const [uploadDocumentName, setUploadDocumentName] = useState('');
  const MAX_RESUME_FILE_SIZE = 5 * 100 * 1024 ; // 500 KB
  const [skills, setSkills] = useState([]);

  const formRef = useRef(null);
  const today = new Date().toISOString().split('T')[0];

  const nextDay = new Date();
  nextDay.setDate(nextDay.getDate() + 1); // Add 1 day to today's date
  const tomorrow = nextDay.toISOString().split('T')[0];

  function validateCandidateData(data){
    let result = {isValid:true,}
    let keyLabels = {
      'candidateResumeId': 'Candidate Resume',
      'relevantExperience': 'Relevant Experience',
      'totalExperience' : 'Total Experience',
      'isCurrentlyEmployed' : 'Is Currently Employed',
      'highestQualification': 'Highest Qualification',
      'timezone2' : 'Timezone 2',
      'slotTime2' : 'SlotTime 2',
      'slotDate2' : 'SlotDate 2',
      'timezone1' : 'Timezone 1',
      'slotTime1' : 'SlotTime 1',
      'slotDate1' : 'SlotDate 1',
      'contactNumber' : 'Contact Number',
      'primarySkills' : 'Primary Skills',
      'currentCountry': 'Current Country',
      'candidateEmailId' : 'Candidate Email ID',
      'candidateName' : 'Candidate Name',
      'jobId' : 'Job Name',
      'customerId': 'Customer Name',
    }
    let required_fields = [
      'candidateResumeId',
      // 'relevantExperience',
      // 'totalExperience',
      // 'isCurrentlyEmployed',
      // 'highestQualification',
      // 'timezone2',
      // 'slotTime2',
      // 'slotDate2',
      'timezone1',
      'slotTime1',
      'slotDate1',
      // 'contactNumber',
      'primarySkills',
      'currentCountry',
      'candidateEmailId',
      'candidateName',
      'jobId',
      'customerId',
    ];
    required_fields.forEach(key=>{
      if (key == 'primarySkills') {
        if(data[key] && data[key].length == 0) {
          result =  {isValid:false,mgs:`Field Missing '${keyLabels[key]}'`}
        }
      } else {
        if(!data[key] || data[key] == ''){
          result =  {isValid:false,mgs:`Field Missing '${keyLabels[key]}'`}
        }
      }
    })
    if (data.slotDate1 != "" || data.slotDate2 != "") {
      if (data.slotDate1 == data.slotDate2 && data.slotTime1 == data.slotTime2) {
        result =  {isValid:false,mgs:`Slot1 and Slot2 cannot be the same'`}
        result.isValid = false;
      }
    }
    return result;
  }

  const [candidateObj, setCandidateData] = useState({
    customerId: customerId,
    custCoordinatorId: 0,
    candidateId: 0,
    panelId: 0,
    assigneeId: 0,
    jobId: 0,
    custCoordinatorName: '',
    custCoordinatorEmailId: '',
    customerCompany: '',
    candidateId: candidateId,
    candidateName: '',
    candidateEmailId: '',
    candidateResumeId: '',
    contactNumber: '',
    alternateContanctNumber: '',
    highestQualification: '',
    course: '',
    institute: '',
    isCurrentlyEmployed: '',
    currentCompany: '',
    currentRole: '',
    currentCountry: 'IND',
    currentState: '',
    currentCity: '',
    totalExperience: '',
    primarySkills: [],
    secondarySkills: [],
    skillFamily: '',
    socialProfile: '',
    slotDate1: '',
    slotTime1: '',
    timezone1: '',
    slotDate2: '',
    slotTime2: '',
    timezone2: '',
    dynamicSkills: [],
    standardSkills: [],
    documents: [],
    overallFeedback: '',
    recommendations: '',
    remarks: '',
    status: '',
    interviewDuration: '',
  });

  useEffect(() => {
    getAssignedUsers().then((res) => {
      setUsers(res.data);
    });

    getJobsInfo().then((res) => {
      const jobs = (res.data).map( job => {
        return {
          'jobId': parseInt(job.jobId),
          'jobName': job.jobName
        }
      });
      setJobs(jobs);
    });

    getCountries().then((res) => {
      setCountries(res.data);
    });
    getSkillFamilies().then((res) => {
      setSkillFamilies(res.data);
    });
    getCustomerCompanies().then((res) => {
      const customers = (res.data).filter( e => e.profileType === 'CUSTOMER').map( cust => {
          return {
            'customerId': parseInt(cust.userId),
            'companyName': cust.companyName
          }
      });
      setCustomers(customers);
    });

  }, []);

  useEffect(() => {
    getCandidate(candidateId).then((res) => {
      res.data.primarySkills = res.data.primarySkills.split(',');
      res.data.secondarySkills = res.data.secondarySkills.split(',');

      getPanelsList(res.data.jobId).then((panels) => {
        setPanels(panels.data);
      });

      setCandidateData(res.data);
      setCandidateResumeName(res.data.candidateResumeId);
      setDynamicSkills(res.data.dynamicSkills);
      setStandardSkills(res.data.standardSkills);
      setDocuments(res.data.documents);
    });
  }, [candidateId]);

  const updateSkillsData = async (skillFamily) => {
    if (skillFamily != '') {
      getSkillsBySkillFamily(skillFamily).then((data) => {
        const skills = data?.data.map(e => ({'label': e.skill, 'value': e.skill}))
        setSkills(skills)
      });
    } else {
      setSkills([])
    }
  }

  const handleCandidateResumeChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile.size > MAX_RESUME_FILE_SIZE) {
      toast.error('File size exceeds the maximum allowed size of 500 KB.');
      setCandidateResume(null);
      setCandidateResumeName('Choose a file');
      candidateObj['candidateResumeId'] = "";
    } else {
      setCandidateResume(selectedFile);
      setCandidateResumeName(selectedFile ? selectedFile.name : 'Choose a file');
      const ext = selectedFile.name?.split('.').pop();
      candidateObj['candidateResumeId'] = `${candidateObj.customerId}_${candidateObj.jobId}_${candidateObj.candidateName.replace(/ /g, '')}.${ext}`;
      setCandidateResumeName(candidateObj['candidateResumeId'])
    }
  }

  const handleUpdateCandidate = async (candidateObj) => {
    try {
      // if(!candidateObj.assigneeId) {
      //   toast.error('Assignee is required to update Candidate details.!')
      //   return;
      // }
      if (candidateResume) {
        const ext = candidateResume?.name?.split('.').pop();
        candidateObj['candidateResumeId'] = `${candidateObj.customerId}_${candidateObj.jobId}_${candidateObj.candidateName.replace(/ /g, '')}.${ext}`;

        candidateObj.primarySkills = candidateObj.primarySkills.join(',');
        candidateObj.secondarySkills = candidateObj.secondarySkills.join(',');
        const response = await updateCandidate(candidateObj._id, candidateObj);
        const resumeFilename = response.data?.candidateResumeId;
        const formData = new FormData();
        formData.append('candidateDocument', candidateResume);
        await uploadCandidateDocument(resumeFilename, formData);
        toast.success('Candidate has been updated.!');
        navigate('/user/candidates/all');
      } else {
        candidateObj.primarySkills = candidateObj.primarySkills.join(',');
        candidateObj.secondarySkills = candidateObj.secondarySkills.join(',');
        await updateCandidate(candidateObj._id, candidateObj);
        toast.success('Candidate has been updated.!');
        navigate('/user/candidates/all');
      }
    } catch (error) {
      console.error('Error posting candidate data:', error);
      toast.error(error.response.data.message);
    }
  };

  const handleUploadDocument = async () => {
    try {
      if (uploadDocument) {
          const ext = uploadDocument?.name?.split('.').pop();
          let docName = `${candidateObj.jobId}_${candidateObj.candidateId}_${uploadDocumentName.trim()}.${ext}`;
          const formData = new FormData();
          formData.append('candidateDocument', uploadDocument);
          await uploadCandidateDocument(docName, formData);
          getCandidate(candidateId).then((res) => {
            setDocuments(res.data.documents);
            setUploadDocument(null);
            setUploadDocumentName('');
          });
          toast.success('Candidate document has been uploaded.!');
        } else {
          toast.error('No document selected to upload.!')
        }
    } catch (error) {
      console.error('Error while uploading document:', error);
      toast.error(error.response.data.message);
    }
  };

  if (hasAllPermissions || userPermissions.includes('EDIT_CANDIDATE')) {

    return (
      <>
        <div className="container-page">
          <div className="main-content" style={{ }}>

            {/* SignleForm */}
            <form ref={formRef}>
              <Tabs
                defaultActiveKey="home"
                className="mb-3 mt-2"
                style={{marginLeft: '20px'}}
                onSelect={(e) => { if (e !== lpChecklistTabKey) { changeLPChecklistTab(e)}}}
              >
                <Tab eventKey="home" title="Candidate Details">
                  <div className="candidate-details" style={{ marginTop: "20px", minHeight: "200px" }}>

                    <div className="row">
                      <div className="col container-heading"><h6>Candidate Details</h6></div>
                    </div>

                    <div className="row">
                      <div className="col">
                      <label>
                        <h6>Customer Name *</h6>
                      </label>
                      <select
                        className="form-control-lg-8 drop-down"
                        style={{ width: "100%", height: "50%" }}
                        name="customerId"
                        value={candidateObj.customerId}
                        onChange={(event) => {
                          setCandidateData((prevCandidateData) => ({
                            ...prevCandidateData,
                            customerId: event.target.value
                          }));
                        }}
                        disabled={true}
                      >
                          <option value="">Select Customer</option>
                            {customersData.map((c) => (
                              <option
                                key={c.customerId}
                                value={c.customerId}
                              >
                                {c.companyName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col">
                        <label><h6>Candidate ID *</h6></label>
                        <input className="form-control editable"
                          placeholder="Candidate ID" name="candidateId"
                          disabled={true}
                          value={candidateObj.candidateId} />
                      </div>
                      <div className="col">
                        <label><h6>Status *</h6></label>
                        <select
                          className="form-control-lg-8 drop-down"
                          style={{ width: "100%", height: "50%" }}
                          name="status"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              status: event.target.value
                            }));
                          }}
                          disabled={(!["ADMIN","SUPER"].includes(user.profileType))}
                          value={candidateObj.status}
                          >
                          <option value="CREATED">CREATED</option>
                          <option value="IN PROGRESS">IN PROGRESS</option>
                          <option value="APPROVED">APPROVED</option>
                          {/* <option value="REJECTED">REJECTED</option> */}
                          <option value="CLAIMED">CLAIMED</option>
                          {/* <option value="CLAIM REJECTED">CLAIM REJECTED</option> */}
                          <option value="SCHEDULED">SCHEDULED</option>
                          <option value="FEEDBACK PENDING">FEEDBACK PENDING</option>
                          <option value="FEEDBACK PROVIDED">FEEDBACK PROVIDED</option>
                          <option value="CLOSED">CLOSED</option>

                        </select>
                      </div>
                    </div>
                    <br></br>
                    <div className="row" >
                      <div className="col">
                        <label><h6>Job Name *</h6></label>
                        <select
                          className="form-control-lg-8 drop-down"
                          style={{ width: "100%", height: "50%" }}
                          name="jobId"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              jobId: event.target.value
                            }));
                          }}
                          value={candidateObj.jobId}
                          disabled={true}
                          >
                          <option value="">Select Job</option>
                            {jobs.map((job) => (
                              <option
                                key={job.jobId}
                                value={job.jobId}
                              >
                              {job.jobName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col">
                        <label><h6>Candidate Name *</h6></label>
                        <input className="form-control editable"
                          placeholder="Enter Candidate name" name="candidateName"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              candidateName: event.target.value,
                            }));
                          }}
                          value={candidateObj.candidateName} />
                      </div>
                      <div className="col">
                        <label><h6>Candidate Email ID *</h6></label>
                        <input className="form-control editable"
                          placeholder="Enter Candidate Email ID" name="candidateEmailId"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              candidateEmailId: event.target.value,
                            }));
                          }}
                          disabled={false}
                          value={candidateObj.candidateEmailId} />
                      </div>
                    </div>

                    <br></br>


                  <div className="row">
                    <div className="col">
                    <label><h6>Skill Family *</h6></label>
                    <select
                      className="form-control-lg-8 drop-down"
                      style={{ width: "100%", height: "50%" }}
                      name="skillFamily"
                      onChange={(event) => {
                        setCandidateData((prevCandidateData) => ({
                          ...prevCandidateData,
                          skillFamily: event.target.value,
                        }));
                        updateSkillsData(event.target.value);
                      }}
                      value={candidateObj.skillFamily}
                      >
                      <option value="">Select Skill Family</option>
                        {skillFamilies.map((sf) => (
                          <option
                            key={sf.skillFamily}
                            value={sf.skillFamily}
                          >
                            {sf.skillFamily}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col">
                    <label><h6>Primary Skills *</h6></label>
                    <CreatableSelect
                      isMulti
                      value={candidateObj.primarySkills.map(skill => ({ value: skill, label: skill }))}
                      onChange={(selectedOptions) => {
                        if (!Array.isArray(selectedOptions)) {
                          return;
                        }
                        const selectedSkills = selectedOptions.map((option) => option.value);
                        setCandidateData((prevCandidateData) => ({
                          ...prevCandidateData,
                          primarySkills: selectedSkills,
                        }));
                      }}
                      onCreateOption={(inputValue) => {
                        setCandidateData((prevCandidateData) => ({
                          ...prevCandidateData,
                          primarySkills: prevCandidateData.primarySkills.concat(inputValue),
                        }));
                      }}
                      options={skills}
                    />
                  </div>

                  <div className="col">
                    <label><h6>Secondary Skills</h6></label>
                    <CreatableSelect
                      isMulti
                      value={candidateObj.secondarySkills != '' ? candidateObj.secondarySkills.map(skill => ({ value: skill, label: skill })) : null}
                      onChange={(selectedOptions) => {
                        if (!Array.isArray(selectedOptions)) {
                          return;
                        }
                        const selectedSkills = selectedOptions.map((option) => option.value);
                        setCandidateData((prevCandidateData) => ({
                          ...prevCandidateData,
                          secondarySkills: selectedSkills,
                        }));
                      }}
                      onCreateOption={(inputValue) => {
                        setCandidateData((prevCandidateData) => ({
                          ...prevCandidateData,
                          secondarySkills: prevCandidateData.secondarySkills.concat(inputValue),
                        }));
                      }}
                      options={skills}
                    />
                  </div>

                </div>

                    <br></br>

                    <div className="row">
                      <div className="col">
                      <label><h6>Country *</h6></label>
                        <select
                          className="form-control-lg-8 drop-down"
                          style={{ width: "100%", height: "50%" }}
                          name="currentCountry"
                          value={candidateObj.currentCountry}
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              currentCountry: event.target.value,
                            }));
                          }}
                        >
                          <option value="">Select Country</option>
                            {countries.map((country) => (
                              <option
                                key={country.countryCode}
                                value={country.countryCode}
                              >
                                {country.countryName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col">
                        <label><h6>Contact Number </h6></label>
                        <input className="form-control editable"
                          placeholder="Enter Contact Number"
                          name="contactNumber"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              contactNumber: event.target.value,
                            }));
                          }}
                          value={candidateObj.contactNumber} />
                      </div>
                      <div className="col">
                        <label><h6>Alternate Contact Number</h6></label>
                        <input className="form-control editable"
                          placeholder="Enter Alternate Number" name="alternateContanctNumber"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              alternateContanctNumber: event.target.value,
                            }));
                          }}
                          value={candidateObj.alternateContanctNumber} />
                      </div>


                    </div>

                    <br></br>
                  <div className="row">
                      <div className="col">
                        <label><h6>Interview Duration *</h6></label>
                        <select
                          className="form-control-lg-8 drop-down"
                          style={{ width: "100%", height: "50%" }}
                          name="interviewDuration"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              interviewDuration: event.target.value,
                            }));
                          }}
                          value={candidateObj.interviewDuration}
                          >
                          <option key={0} value={'30 Mins'}>{'30 Mins'}</option>
                          <option key={1} value={'60 Mins'}>{'60 Mins'}</option>
                        </select>
                      </div>
                      <div className="col">

                      </div>
                      <div className="col">

                      </div>

                    </div>

                  </div>

                  <div className="candidate-details"
                  style={{ marginTop: "20px", minHeight:"100px",
                  display: ["ADMIN","SUPER"].includes(user.profileType) ? 'block': 'none'
                  }}>
                    <div className="row">
                      <div className="col container-heading"><h6>Rate Card Details</h6></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label><h6>Rate Category *</h6></label>
                        <select
                          className="form-control-lg-8 drop-down"
                          style={{ width: "100%", height: "50%" }}
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              rateCategory: event.target.value,
                            }));
                          }}
                          name="rateCategory"
                          value={candidateObj.rateCategory}
                          >
                          <option key={0} value={'ratesInIndia'}>{'Rates In India'}</option>
                          <option key={1} value={'ratesInUS'}>{'Rates In US'}</option>
                          <option key={2} value={'ratesInternal'}>{'Rates Internal'}</option>
                          <option key={3} value={'ratesExternal'}>{'Rates External'}</option>
                        </select>
                      </div>

                      <div className="col">
                        <label><h6>Rate Card *</h6></label>
                        <select
                          className="form-control-lg-8 drop-down"
                          style={{ width: "100%", height: "50%" }}
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              rateCard: event.target.value,
                            }));
                          }}
                          name="rateCard"
                          value={candidateObj.rateCard}
                          >
                          <option key={0} value={'highDemand'}>{'High Demand'}</option>
                          <option key={1} value={'niche'}>{'Niche'}</option>
                          <option key={2} value={'legacy'}>{'Legacy'}</option>
                          <option key={3} value={'leadership'}>{'Leadership'}</option>
                          <option key={4} value={'others'}>{'Others'}</option>
                        </select>
                      </div>

                      <div className="col">
                        <label><h6>Is Bonus Applicable *</h6></label>
                        <select
                          className="form-control-lg-8 drop-down"
                          style={{ width: "100%", height: "50%" }}
                          name="isBonusApplicable"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              isBonusApplicable: event.target.value,
                            }));
                          }}
                          value={candidateObj.isBonusApplicable}
                          >
                          <option key={0} value={true}>Yes</option>
                          <option key={1} value={false}>No</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="candidate-details"
                    style={{
                      marginTop: "20px", minHeight:"200px",
                      display: ['FEEDBACK PROVIDED','CLOSED'].includes(candidateObj.status) ? 'none': 'block'
                    }}
                  >
                    <div className="row">
                      <div className="col container-heading"><h6>Candidate Slots</h6></div>
                    </div>
                    <div className="availability-slot">
                      <div className="row">
                        <div className="col container-heading">Availability Slot 1</div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label>
                            <h6>Date *</h6>
                          </label>
                          <input
                            type="date"
                            className="form-control editable "
                            placeholder="Select Date"
                            name="slotDate1"
                            onChange={(event) => {
                              let slotDate1 = event.target.value;
                              console.log(slotDate1, candidateObj.slotTime1, candidateObj.slotDate2 ,  candidateObj.slotTime2 )
                              if (slotDate1 === candidateObj.slotDate2 && candidateObj.slotTime1 == candidateObj.slotTime2) {
                                toast.warning("Slot1 and Slot2 cannot be the same");
                              }
                              setCandidateData((prevCandidateData) => ({
                                ...prevCandidateData,
                                slotDate1 : event.target.value,
                              }));
                            }}
                            min={['CUSTOMER', 'CUSTOMER_ADMIN'].includes(user.profileType) ? tomorrow : today}
                            value={candidateObj.slotDate1}
                          />
                        </div>
                        <div className="col">
                          <label>
                            <h6>Time *</h6>
                          </label>
                          <select
                            className="form-control-lg-8 drop-down"
                            style={{ width: "100%", height: "50%" }}
                            name="slotTime1"
                            onChange={(event) => {
                              let slotTime1 = event.target.value;
                              console.log(candidateObj.slotDate1, slotTime1, candidateObj.slotDate2 ,  candidateObj.slotTime2)
                              if (candidateObj.slotDate1 == candidateObj.slotDate2 && slotTime1 == candidateObj.slotTime2) {
                                toast.warning("Slot1 and Slot2 cannot be the same");
                              }
                              setCandidateData((prevCandidateData) => ({
                                ...prevCandidateData,
                                slotTime1 : event.target.value,
                              }));
                            }}
                            value={candidateObj.slotTime1}
                          >
                            {timeOptions.map((option, index) => (
                              <option key={index} value={option.value}>{option.label}</option>
                            ))}
                          </select>
                        </div>

                        <div className="col">
                          <label>
                            <h6>Timezone *</h6>
                          </label>
                          <select
                            className="form-control-lg-8 drop-down"
                            style={{ width: "100%", height: "50%" }}
                            name="timezone1"
                            onChange={(event) => {
                              setCandidateData((prevCandidateData) => ({
                                ...prevCandidateData,
                                timezone1 : event.target.value,
                              }));
                            }}
                            value={candidateObj.timezone1}
                          >
                            {
                              getListOfTimezones().map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="availability-slot">
                      <div className="row">
                        <div className="col container-heading">Availability Slot 2</div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label>
                            <h6>Date </h6>
                          </label>
                          <input
                            type="date"
                            className="form-control editable "
                            placeholder="Select Date"
                            name="slotDate2"
                            onChange={(event) => {
                              let slotDate2 = event.target.value;
                              console.log(candidateObj.slotDate1, candidateObj.slotTime1, slotDate2 ,  candidateObj.slotTime2 )
                              if (candidateObj.slotDate1 == slotDate2 && candidateObj.slotTime1 == candidateObj.slotTime2) {
                                toast.warning("Slot1 and Slot2 cannot be the same");
                              }
                              setCandidateData((prevCandidateData) => ({
                                ...prevCandidateData,
                                slotDate2 : event.target.value,
                              }));
                            }}
                            min={['CUSTOMER', 'CUSTOMER_ADMIN'].includes(user.profileType) ? tomorrow : today}
                            value={candidateObj.slotDate2}
                          />
                        </div>
                        <div className="col">
                          <label>
                            <h6>Time </h6>
                          </label>
                          <select
                            className="form-control-lg-8 drop-down"
                            style={{ width: "100%", height: "50%" }}
                            name="slotTime2"
                            onChange={(event) => {
                              let slotTime2 = event.target.value;
                              console.log(candidateObj.slotDate1, candidateObj.slotTime1, candidateObj.slotDate2 ,  slotTime2)
                              if (candidateObj.slotDate1 == candidateObj.slotDate2 && candidateObj.slotTime1 == slotTime2) {
                                toast.warning("Slot1 and Slot2 cannot be the same");
                              }
                              setCandidateData((prevCandidateData) => ({
                                ...prevCandidateData,
                                slotTime2 : event.target.value,
                              }));
                            }}
                            value={candidateObj.slotTime2}
                          >
                            {timeOptions.map((option, index) => (
                              <option key={index} value={option.value}>{option.label}</option>
                            ))}
                          </select>
                        </div>

                        <div className="col">
                          <label>
                            <h6>Timezone </h6>
                          </label>
                          <select
                            className="form-control-lg-8 drop-down"
                            style={{ width: "100%", height: "50%" }}
                            name="timezone2"
                            onChange={(event) => {
                              setCandidateData((prevCandidateData) => ({
                                ...prevCandidateData,
                                timezone2 : event.target.value,
                              }));
                            }}
                            value={candidateObj.timezone2}
                          >
                            {
                              getListOfTimezones().map((option, index) => (
                                <option key={index} value={option.value}>{option.label}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="candidate-details" style={{ marginTop: "20px", minHeight:"100px" }}>
                    <div className="row">
                      <div className="col container-heading"><h6>Education Details</h6></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label><h6>Highest Qualification? </h6></label>
                        <input className="form-control editable"
                        placeholder="Ex: Computers Engineering"
                        onChange={(event) => {
                          setCandidateData((prevCandidateData) => ({
                            ...prevCandidateData,
                            highestQualification: event.target.value,
                          }));
                        }}
                        name="highestQualification"
                        value={candidateObj.highestQualification}/>
                      </div>
                      <div className="col">
                        <label><h6>Course </h6></label>
                        <input className="form-control editable"
                        placeholder="Ex: Computers Engineering"
                        onChange={(event) => {
                          setCandidateData((prevCandidateData) => ({
                            ...prevCandidateData,
                            course: event.target.value,
                          }));
                        }}
                        name="course"
                        value={candidateObj.course}/>
                      </div>
                      <div className="col">
                        <label><h6>Institute </h6></label>
                        <input className="form-control editable"
                        placeholder="Enter Institute Name"
                        onChange={(event) => {
                          setCandidateData((prevCandidateData) => ({
                            ...prevCandidateData,
                            institute: event.target.value,
                          }));
                        }}
                        name="institute"
                        value={candidateObj.institute}/>
                      </div>
                    </div>

                  </div>

                  <div className="candidate-details" style={{ marginTop: "20px", minHeight:"240px" }}>
                    <div className="row">
                      <div className="col container-heading"><h6>Employment Details</h6></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label><h6>Are you currently Employed? </h6></label>
                        <select
                          className="form-control-lg-8 drop-down"
                          style={{ width: "100%", height: "50%" }}
                          name="isCurrentlyEmployed"
                          value={candidateObj.isCurrentlyEmployed}
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              isCurrentlyEmployed: event.target.value,
                            }));
                          }}
                          >
                          <option value="">Select</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      <div className="col">
                        <label><h6>Total Experience </h6></label>
                        <input className="form-control editable"
                        placeholder="Ex: 5 years 2 Months"
                        onChange={(event) => {
                          setCandidateData((prevCandidateData) => ({
                            ...prevCandidateData,
                            totalExperience: event.target.value,
                          }));
                        }}
                        name="totalExperience"
                        value={candidateObj.totalExperience}/>
                      </div>
                      <div className="col">
                        <label><h6>Relevant Experience </h6></label>
                        <input className="form-control editable"
                        placeholder="Ex: 5 years 2 Months"
                        onChange={(event) => {
                          setCandidateData((prevCandidateData) => ({
                            ...prevCandidateData,
                            relevantExperience: event.target.value,
                          }));
                        }}
                        name="relevantExperience"
                        value={candidateObj.relevantExperience}/>
                      </div>
                    </div>

                    <br></br>

                    <div className="row">
                      <div className="col">
                        <label><h6>Current Company</h6></label>
                        <input className="form-control editable"
                        placeholder="Enter Current Company"
                        onChange={(event) => {
                          setCandidateData((prevCandidateData) => ({
                            ...prevCandidateData,
                            currentCompany: event.target.value,
                          }));
                        }}
                        name="currentCompany"
                        value={candidateObj.currentCompany}/>
                      </div>
                      <div className="col">
                        <label><h6>Current Role/Designation</h6></label>
                        <input className="form-control editable"
                        placeholder="Enter Current Role"
                        onChange={(event) => {
                          setCandidateData((prevCandidateData) => ({
                            ...prevCandidateData,
                            currentRole: event.target.value,
                          }));
                        }}
                        name="currentRole"
                        value={candidateObj.currentRole}/>
                      </div>
                      <div className="col">
                      </div>
                    </div>
                  </div>

                  <div className="candidate-details" style={{ marginTop: "20px", minHeight:"100px" }}>
                    <div className="row">
                      <div className="col container-heading"><h6>Social Profile Link</h6></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {/* <label><h6>Social Profile </h6></label> */}
                        <input className="form-control editable"
                        placeholder="Ex: http://linkedin.in/your-name"
                        onChange={(event) => {
                          setCandidateData((prevCandidateData) => ({
                            ...prevCandidateData,
                            socialProfile: event.target.value,
                          }));
                        }}
                        name="socialProfile"
                        value={candidateObj.socialProfile}/>
                      </div>
                      <div className="col">

                      </div>
                    </div>

                  </div>

                  <div className="comments-remarks" style={{ marginTop: "20px",marginBottom: "20px", paddingTop: "20px" }} >
                  <div className="row">
                      <div className="col container-heading"><h6>Upload Candidate Resume *</h6></div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label
                          for="candidateResume"
                          style={{
                            height: "50px",
                            width: "600px",
                            background: "#EEF4FF 0% 0% no-repeat padding-box",
                            borderRadius: "4px",
                            opacity: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                        <span
                          style={{
                            width: "600px",
                            height: "50px",
                            background: "#EEF4FF 0% 0% no-repeat padding-box",
                            borderRadius: "4px",
                            opacity: 1,
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                          }}
                        >
                          <img src="/Images/pdfIcon.svg" style={{paddingLeft: '20px'}} />
                          <span style={{paddingLeft: '10px'}}> {"  "} </span>
                          <span style={{ color: "#3E67B1", paddingLeft: '10px', paddingRight: '10px' }}> Browse </span>
                          <span style={{marginLeft: '2%'}}>{candidateResumeName}</span>
                          <span>
                            <i className="fa-solid fa-download"
                              style={{
                                cursor: 'pointer',
                                paddingLeft: '20px'
                              }}
                              onClick={async (e) => {
                                e.preventDefault(true)
                                console.log("Clicked", candidateObj.candidateResumeId)
                                let documentId = candidateObj.candidateResumeId;
                                try {
                                  let data = await getCandidateDocumentById(documentId);
                                  const blobUrl = URL.createObjectURL(data);
                                  const link = document.createElement('a');
                                  link.href = blobUrl;
                                  link.download = documentId;
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);
                                  URL.revokeObjectURL(blobUrl);
                                  toast.success('Downloaded resume successfully.!')
                                } catch (error) {
                                  toast.error('Resume not exists!')
                                }
                              }}
                              >
                              </i>
                            </span>
                        </span>
                        <input
                          style={{ display: "none" }}
                          id="candidateResume"
                          type="file"
                          name="candidateResume"
                          accept=".pdf,.docx"
                          onChange={handleCandidateResumeChange}
                        />
                      </label>
                      <i>
                      Only PDF/DOCX Files Supported with Size lessthan 500KB
                      </i>
                    </div>
                  </div>
                  </div>

                  <div className="comments-remarks" >
                    <div className="row"
                      style={{
                        display: ['ADMIN','SUPER'].includes(user.profileType) ? 'flex' : 'none'
                      }}
                    >
                      <div className="col">
                        <label><h6>Assignee *</h6></label>
                        <select
                          className="form-control-lg-8 drop-down"
                          style={{ width: "100%", height: "50%" }}
                          name="assigneeId"
                          value={candidateObj.assigneeId}
                          onChange={(event) => {
                            let assignee = users.filter((d) => d.userId == event.target.value)[0];
                            if (assignee) {
                              setCandidateData((prevCandidateData) => ({
                                ...prevCandidateData,
                                assigneeId: event.target.value,
                                assigneeName: assignee?.username,
                                assigneeEmailId: assignee?.emailId,
                              }));
                            } else {
                              setCandidateData((prevCandidateData) => ({
                                ...prevCandidateData,
                                assigneeId: null,
                                assigneeName: '',
                                assigneeEmailId: '',
                              }));
                            }
                          }}
                        >
                          <option value="">Select Assignee</option>
                          {users.map((user) => (
                            <option key={user.userId} value={user.userId}>
                              {user.firstName} {user.lastName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col">
                        <label><h6>Assignee Email ID</h6></label>
                        <input type="text" className="form-control editable"
                        placeholder="" name="assigneeEmailId"
                        disabled={true}
                        value={candidateObj.assigneeEmailId}/>
                      </div>
                      <div className="col">
                      </div>

                    </div>
                    <br
                    style={{
                      display: ['ADMIN','SUPER'].includes(user.profileType) ? 'flex' : 'none'
                    }}
                    ></br>
                    <div className="row"
                      style={{
                        display: ['ADMIN','SUPER'].includes(user.profileType) ? 'flex' : 'none'
                      }}
                    >
                      <div className="col">
                        <label><h6>Panel</h6></label>
                        <select
                          className="form-control-lg-8 drop-down"
                          style={{ width: "100%", height: "50%" }}
                          name="panelId"
                          value={candidateObj.panelId}
                          onChange={(event) => {
                            let panel = panels.filter((d) => d.userId == event.target.value)[0];
                            if (panel) {
                              setCandidateData((prevCandidateData) => ({
                                ...prevCandidateData,
                                panelId: event.target.value,
                                panelEmailId: panel?.emailId,
                                panelName: panel?.firstName,
                              }));
                            } else {
                              setCandidateData((prevCandidateData) => ({
                                ...prevCandidateData,
                                panelId: 0,
                                panelEmailId: '',
                                panelName: '',
                              }));
                            }
                          }}
                        >
                          <option value="">Select Panel</option>
                          {panels.map((panel) => (
                            <option key={panel.userId} value={panel.userId}>
                              {panel.firstName} {panel.lastName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col">
                        <label><h6>Panel Email ID</h6></label>
                        <input type="text" className="form-control editable"
                        placeholder="" name="panelEmailId"
                        disabled={true}
                        value={candidateObj.panelEmailId}/>
                      </div>
                      <div className="col">
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <label><h6>Remarks</h6></label>
                      <div className="col-10">
                        <textarea
                          rows="3"
                          className="form-control editable "
                          placeholder="Enter Remarks"
                          name="remarks"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              remarks: event.target.value,
                            }));
                          }}
                          value={candidateObj.remarks}
                        />
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: '20px', textAlign: 'center', paddingBottom: '5px' }}>
                      <div className="col" style={{ textAlign: 'center' }}>
                        <button
                          type="button"
                          className="btn btn-outline-primary me-2"
                          style={{
                            border: '1px solid #3E67B1',
                            borderRadius: ' 4px',
                            opacity: '1',
                            width: '96px',
                            height: '50px',
                          }}
                          onClick={()=>{
                            navigate(-1);
                          }}
                        >
                          BACK
                        </button>
                        <button
                        onClick={() => {
                          const validation = validateCandidateData(candidateObj);
                          if(!validation.isValid){
                            toast.error(validation.mgs)
                            return;
                          }
                          // if(!candidateResume) {
                          //   toast.error('Candidate Resume is missing.!')
                          // }
                          handleUpdateCandidate(candidateObj);
                        }}
                          type="button"
                          className="btn btn-primary"
                          style={{
                            border: '1px solid #3E67B1',
                            borderRadius: ' 4px',
                            opacity: '1',
                            width: '96px',
                            height: '50px',
                            display: (hasAllPermissions || userPermissions.includes('EDIT_CANDIDATE')) ? 'inline' : 'none'
                          }}
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="start" title="Assessment Skills">
                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                    <div className="row">
                      <div className="col text-left"><h6>Skill Assessment</h6></div>
                    <div className="col" style={{textAlign:'right'}}>
                      <a
                        style={{
                          cursor: 'pointer',
                          color: '#1976d2'
                        }}
                        onClick={() => {
                          setDynamicSkills((prev) => [
                            ...prev,
                            {
                              skillIndex: prev.length,
                              "skillTopic": "",
                              "expectedProficiency": "",
                              "possessProficiency": "",
                              "rating": "",
                              "comments": "",
                            }
                          ]);
                        }}
                      >
                        Add New&nbsp; <img src="/Images/Add.svg" />
                      </a>
                    </div>

                    </div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th >Topic/Technology</th>
                          <th >Expected Profeciency</th>
                          <th>Possess Profeciency</th>
                          <th style={{textAlign:'left'}}>Rating</th>
                          <th style={{textAlign:'left'}}>Remarks/Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                      {dynamicSkills.map((cell, index) => (
                        <tr key={cell.skillIndex}>
                          <td className="col-2">
                            <input
                              className="form-control editable"
                              type="text"
                              disabled={true}
                              onChange={(event) => {
                                setDynamicSkills((prev) => {
                                  const updatedSkills = [...prev];
                                  updatedSkills[index].skillTopic = event.target.value;
                                  return updatedSkills;
                                });
                              }}
                              style={{ height: '30px', width: "100%" }}
                              name="skillTopic"
                              value={cell.skillTopic}
                            />
                          </td>
                          <td className="col-2">
                            <select
                              className="form-control-lg-8 drop-down"
                              disabled={true}
                              onChange={(event) => {
                                setDynamicSkills((prev) => {
                                  const updatedSkills = [...prev];
                                  updatedSkills[index].expectedProficiency = event.target.value;
                                  return updatedSkills;
                                });
                              }}
                              name="expectedProficiency"
                              value={cell.expectedProficiency}
                              style={{height: '30px', borderRadius:'5px', width: "100%"}}
                              >
                              <option key={0} value=''>Select</option>
                              <option key={1} value='BEGINNER'>BEGINNER</option>
                              <option key={2} value='AVERAGE'>AVERAGE</option>
                              <option key={3} value='INTERMEDIATE'>INTERMEDIATE</option>
                              <option key={4} value='GOOD'>GOOD</option>
                              <option key={5} value='ADVANCED'>ADVANCED</option>
                            </select>

                          </td>
                          <td className="col-2">
                            <select
                              className="form-control-lg-8 drop-down"
                              onChange={(event) => {
                                setDynamicSkills((prev) => {
                                  const updatedSkills = [...prev];
                                  updatedSkills[index].possessProficiency = event.target.value;
                                  return updatedSkills;
                                });
                              }}
                              disabled={['FEEDBACK PROVIDED','CLOSED'].includes(candidateObj.status) || ['CUSTOMER','CUSTOMER_ADMIN'].includes(user.profileType)}
                              name="possessProficiency"
                              value={cell.possessProficiency}
                              style={{height: '30px', borderRadius:'5px', width: "100%"}}
                              >
                              <option key={0} value=''>Select</option>
                              <option key={1} value='BEGINNER'>BEGINNER</option>
                              <option key={2} value='AVERAGE'>AVERAGE</option>
                              <option key={3} value='INTERMEDIATE'>INTERMEDIATE</option>
                              <option key={4} value='GOOD'>GOOD</option>
                              <option key={5} value='ADVANCED'>ADVANCED</option>
                            </select>
                          </td>
                          <td className="col-2">
                            <select
                              className="form-control-lg-8 drop-down"
                              onChange={(event) => {
                                setDynamicSkills((prev) => {
                                  const updatedSkills = [...prev];
                                  updatedSkills[index].rating = event.target.value;
                                  return updatedSkills;
                                });
                              }}
                              disabled={['FEEDBACK PROVIDED','CLOSED'].includes(candidateObj.status) || ['CUSTOMER','CUSTOMER_ADMIN'].includes(user.profileType)}
                              name="rating"
                              value={cell.rating}
                              style={{height: '30px', borderRadius:'5px', width: "100%"}}
                              >
                              <option key={0} value=''>Select</option>
                              <option key={1} value='1'>&#9733; &#9734; &#9734; &#9734; &#9734;</option>
                              <option key={2} value='2'>&#9733; &#9733; &#9734; &#9734; &#9734;</option>
                              <option key={3} value='3'>&#9733; &#9733; &#9733; &#9734; &#9734;</option>
                              <option key={4} value='4'>&#9733; &#9733; &#9733; &#9733; &#9734;</option>
                              <option key={5} value='5'>&#9733; &#9733; &#9733; &#9733; &#9733;</option>
                            </select>
                          </td>
                          <td className="col-4">
                            <input
                              className="form-control editable"
                              type="text"
                              onChange={(event) => {
                                const { value } = event.target;
                                setDynamicSkills((prev) => {
                                  const updatedSkills = prev.map((skill) => {
                                    if (skill.skillIndex === cell.skillIndex) {
                                      return { ...skill, comments: value };
                                    }
                                    return skill;
                                  });
                                  return updatedSkills;
                                });
                              }}
                              style={{ height: '30px', width: "100%" }}
                              name="comments"
                              disabled={['FEEDBACK PROVIDED','CLOSED'].includes(candidateObj.status) || ['CUSTOMER','CUSTOMER_ADMIN'].includes(user.profileType)}
                              value={cell.comments}
                            />
                          </td>
                          <td>
                            <span
                              style={{
                                cursor: 'pointer',
                                marginTop: '10px'
                              }}
                            >
                              <a onClick={() => {
                                let skills = dynamicSkills.filter(s => s.skillIndex != cell.skillIndex);
                                setDynamicSkills(skills);
                                // setDynamicSkills((skills) => skills.filter((_, skillIndex) => skillIndex !== cell.skillIndex));
                              }}><img
                                style={{marginTop: '15px'}}
                                src="/Images/Subtract.svg"/>
                                </a>
                            </span>
                          </td>

                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                    <div className="row">
                      <div className="col text-left"><h6>Standard Competency Description</h6></div>
                    </div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th >Topic/Skill</th>
                          <th style={{textAlign:'left'}}>Rating</th>
                          <th style={{textAlign:'left'}}>Remarks/Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                      {standardSkills.map((cell) => (
                        <tr key={cell.skillIndex}>
                          <td className="col-6">
                            <span>
                            {cell.skillTopic}
                            </span>
                          </td>
                          <td className="col-2">
                          <select
                            className="form-control-lg-8 drop-down"
                            onChange={(event) => {
                              setStandardSkills((prev) => {
                                const updatedSkills = [...prev];
                                updatedSkills[cell.skillIndex].rating = event.target.value;
                                return updatedSkills;
                              });
                            }}
                            name="rating"
                            disabled={['FEEDBACK PROVIDED','CLOSED'].includes(candidateObj.status) || ['CUSTOMER','CUSTOMER_ADMIN'].includes(user.profileType)}
                            value={cell.rating}
                            style={{height: '30px', borderRadius:'5px', width: "100%"}}
                            >
                            <option key={0} value=''>Select</option>
                            <option key={1} value='1'>&#9733; &#9734; &#9734; &#9734; &#9734;</option>
                            <option key={2} value='2'>&#9733; &#9733; &#9734; &#9734; &#9734;</option>
                            <option key={3} value='3'>&#9733; &#9733; &#9733; &#9734; &#9734;</option>
                            <option key={4} value='4'>&#9733; &#9733; &#9733; &#9733; &#9734;</option>
                            <option key={5} value='5'>&#9733; &#9733; &#9733; &#9733; &#9733;</option>
                          </select>
                          </td>
                          <td className="col-4">
                            <input
                              className="form-control editable"
                              type="text"
                              onChange={(event) => {
                                const { value } = event.target;
                                setStandardSkills((prev) => {
                                  const updatedSkills = prev.map((skill) => {
                                    if (skill.skillIndex === cell.skillIndex) {
                                      return { ...skill, comments: value };
                                    }
                                    return skill;
                                  });
                                  return updatedSkills;
                                });
                              }}
                              style={{ height: '30px', width: "100%" }}
                              name="comments"
                              disabled={['FEEDBACK PROVIDED','CLOSED'].includes(candidateObj.status) || ['CUSTOMER','CUSTOMER_ADMIN'].includes(user.profileType)}
                              value={cell.comments}
                            />
                          </td>
                        </tr>

                      ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                    <div className="row">
                      <div className="col-4">
                        <label>
                          <h6>Overall Feedback</h6>
                        </label>
                        <select
                          className="form-control-lg drop-down"
                          style={{ width: "100%", height: "50%" }}
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              overallFeedback: event.target.value
                            }));
                          }}
                          name="overallFeedback"
                          disabled={['FEEDBACK PROVIDED','CLOSED'].includes(candidateObj.status) || ['CUSTOMER','CUSTOMER_ADMIN'].includes(user.profileType)}
                          value={candidateObj.overallFeedback}
                        >
                          <option value="">Select </option>
                          <option value="SELECTED">SELECTED</option>
                          <option value="NEAR MATCH">NEAR MATCH</option>
                          <option value="TRAINABLE">TRAINABLE</option>
                          <option value="HOLD">HOLD</option>
                          <option value="REJECTED">REJECTED</option>
                        </select>
                      </div>
                      <div className="col-8" >
                        <label>
                          <h6>Recommendations</h6>
                        </label>
                        <input
                          type="text"
                          className="form-control editable"
                          placeholder="Enter Recommendations"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              recommendations: event.target.value
                            }));
                          }}
                          name="recommendations"
                          disabled={['FEEDBACK PROVIDED','CLOSED'].includes(candidateObj.status) || ['CUSTOMER','CUSTOMER_ADMIN'].includes(user.profileType)}
                          value={candidateObj.recommendations}
                        />
                      </div>

                    </div>
                    <br></br>
                    <div
                      className="row"
                      style={{display: (hasAllPermissions || user.profileType == 'ADMIN') ? 'block': 'none'}}
                    >
                      <div className="col-12" >
                        <label>
                          <h6>Recorded Video URL</h6>
                        </label>
                        <input
                          type="text"
                          className="form-control editable"
                          placeholder="Enter Recorded Video URL"
                          onChange={(event) => {
                            setCandidateData((prevCandidateData) => ({
                              ...prevCandidateData,
                              recordedVideoUrl: event.target.value
                            }));
                          }}
                          name="recordedVideoUrl"
                          value={candidateObj.recordedVideoUrl}
                        />
                      </div>

                    </div>
                    <div className="row" style={{ marginTop: '20px', textAlign: 'center', paddingBottom: '5px' }}>
                      <div className="col" style={{ textAlign: 'center' }}>
                        <button
                          type="button"
                          className="btn btn-outline-primary me-2"
                          style={{
                            border: '1px solid #3E67B1',
                            borderRadius: ' 4px',
                            opacity: '1',
                            width: '96px',
                            height: '50px',
                          }}
                          onClick={()=>{
                            navigate('/user/candidates/all');
                          }}
                        >
                          BACK
                        </button>
                        <button
                        disabled={['CLOSED'].includes(candidateObj.status)}
                        onClick={() => {
                          updateSkillsForCandidate(candidateId, {
                            dynamicSkills,
                            standardSkills,
                            overallFeedback: candidateObj['overallFeedback'],
                            recommendations: candidateObj['recommendations'],
                            recordedVideoUrl: candidateObj['recordedVideoUrl']
                          }).then((res) => {
                            toast.success(`Assessment Feedback has been updated.!`);
                              res.data.primarySkills = res.data.primarySkills.split(',');
                              res.data.secondarySkills = res.data.secondarySkills.split(',');
                              setCandidateData(res.data);
                              setDynamicSkills(res.data.dynamicSkills);
                              setStandardSkills(res.data.standardSkills);
                              navigate('/user/candidates/all');
                          }).catch((err)=> {
                            console.error(err);
                            toast.error(`Unable to update Assessment Skills.!`);
                          })
                        }}
                          type="button"
                          className="btn btn-primary"
                          style={{
                            border: '1px solid #3E67B1',
                            borderRadius: ' 4px',
                            opacity: '1',
                            width: '96px',
                            height: '50px',
                            display: (hasAllPermissions || userPermissions.includes('UPDATE_FEEDBACK')) ? 'inline' : 'none'
                          }}
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="comments-remarks" style={{ margin: "20px", padding: "20px" }} >
                    <div className="row">
                      <div className="col-5">
                        <label>
                          <h6>Upload Documents</h6>
                        </label>
                        <label
                          for="uploadDocument"
                          style={{
                            height: "50px",
                            width: "100%",
                            background: "#EEF4FF 0% 0% no-repeat padding-box",
                            borderRadius: "4px",
                            opacity: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              height: "50px",
                              width: "100%",
                              background: "#EEF4FF 0% 0% no-repeat padding-box",
                              borderRadius: "4px",
                              opacity: 1,
                              display: "flex",
                              alignItems: "center",
                              alignContent: "center",
                            }}
                          >
                            <img src="/Images/pdfIcon.svg" style={{paddingLeft: '20px'}} />
                            {/* <span style={{paddingLeft: '10px'}}> {"  "} </span> */}
                            <span style={{ color: "#3E67B1", paddingLeft: '10px', paddingRight: '10px' }}> Browse </span>
                            <span style={{marginLeft: '10px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',  maxWidth: '50%' }}> {uploadDocument?.name}</span>

                          </span>
                          <input
                            style={{ display: "none" }}
                            id="uploadDocument"
                            type="file"
                            name="uploadDocument"
                            accept=".pdf,.jpg,.png,.docx"
                            onChange={(e) => {
                              const documentFile = e.target.files[0];
                              if (documentFile.size > MAX_RESUME_FILE_SIZE) {
                                toast.error('File size exceeds the maximum allowed size of 500 KB.');
                                setUploadDocument(null);
                              } else {
                                setUploadDocument(documentFile);
                              }
                            }}
                          />
                        </label>
                        <span> Size must be lessthan 500KB </span>
                      </div>
                      <div className="col-4">
                        <label>
                          <h6>Document Name *</h6>
                        </label>
                        <input
                          type="text"
                          className="form-control editable"
                          placeholder="Enter Document Name"
                          onChange={(event) => {
                            let value = (event.target.value).replace(/[._@-]/g, '');
                            setUploadDocumentName(value);
                          }}
                          name="uploadDocumentName"
                          value={uploadDocumentName}
                        />

                      </div>
                      <div className="col-3">
                        <button
                          onClick={() => {
                            if (uploadDocumentName == '') {
                              toast.error('Document Name missing')
                              return;
                            }
                            if (!uploadDocument) {
                              toast.error('Document missing')
                              return;
                            }
                            handleUploadDocument()
                          }}
                          type="button"
                          className="btn btn-primary"
                          style={{
                            border: '1px solid #3E67B1',
                            borderRadius: ' 4px',
                            opacity: '1',
                            width: '130px',
                            height: '50px',
                            marginTop: '30px',
                            display: (hasAllPermissions || userPermissions.includes('UPDATE_FEEDBACK')) ? 'inline' : 'none'
                          }}
                        >
                          UPLOAD
                        </button>

                      </div>
                    </div>
                    <div className="row">
                      <table className="table">
                        <thead>
                          <tr>
                            <th >Document Name</th>
                            <th style={{textAlign:'left'}}>Document Link</th>
                            <th style={{textAlign:'left'}}> Actions </th>
                          </tr>
                        </thead>
                        <tbody>
                        {documents.map((cell) => (
                          <tr key={cell.skillIndex}>
                            <td className="col-4">
                              <span>
                              {cell.documentName}
                              </span>
                            </td>
                            <td className="col-4">
                              <span>
                              {cell.documentId}
                              </span>
                            </td>
                            <td className="col-4">
                              <span>
                                <i className="fa-solid fa-download"
                                style={{
                                  cursor: 'pointer'
                                }}
                                onClick={async (e) => {
                                  e.preventDefault(true)
                                  console.log("Clicked", cell.documentId)
                                  let documentId = cell.documentId;
                                  try {
                                    let data = await getCandidateDocumentById(documentId);
                                    const blobUrl = URL.createObjectURL(data);
                                    const link = document.createElement('a');
                                    link.href = blobUrl;
                                    link.download = documentId;
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                    URL.revokeObjectURL(blobUrl);
                                    toast.success('Downloaded document successfully.!')
                                  } catch (error) {
                                    console.log(error)
                                    toast.error('Document not exists!')
                                  }
                                }}
                                >
                                </i>
                              </span>
                              <span>
                                <i className="fa-solid fa-trash-can"
                                style={{
                                  cursor: 'pointer',
                                  marginLeft: '20px'
                                }}
                                onClick={async (e) => {
                                  e.preventDefault(true)
                                  deleteDocument(candidateId, cell.documentId).then((res) => {
                                    toast.success('Deleted the Document.')
                                    setDocuments(res.data.documents)
                                  }).catch((err) => {
                                    console.log(err);
                                    toast.error('Unable to delete Document.')
                                  })
                                }}
                                >
                                </i>
                              </span>
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                    </div>
                  </div>

                  <br></br>
                  <br></br>

                </Tab>

              </Tabs>
            </form>

          </div>

        </div>
      </>
    );

  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
