import React, {useEffect, useState} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
    deleteCandidate,
    getAssignedUsers, getCandidateDocumentById,
    getCandidates, updateCandidate,// getSkillFamilies
    getCustomerDashboardMetrics, getAdminDashboardMetrics,
} from "../../service/api";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import FilterModal from "./FilterModel";
import "./Candidate.css";
import "../../CSS/dashboard.css";
import {toast} from "react-toastify";
import CandidateModal from "./CandidateModal";
import Papa from 'papaparse';

function filterDataByDateRange(data, startDate, endDate) {
    let filteredData = data.filter((item) => {
        const itemDate = new Date(item.createdAt).toISOString().split('T')[0];
        return itemDate >= startDate && itemDate <= endDate;
    });
    filteredData = filteredData.sort((a, b) => b.candidateId - a.candidateId);
    return filteredData;
  }

export default function Candidates() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dashboardFilter = queryParams.get('dashboardFilter');

    const user = JSON.parse(localStorage.getItem('user'));
    const userPermissions = user.permissions.map(e => e.permissionName);
    const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
    const {filterOptions} = useParams();
    const [searchStatus, setSearchStatus] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [candidatesData, setCandidatesData] = useState([]);
    const [skillFamilyData, setSkillFamiliesData] = useState([]);
    const [skillFamilyDict, setSkillFamiliesDict] = useState({});
    const [filteredCandidatesData, setFilteredCandidates] = useState([]);
    const [dataLoading, setDataLoading] = useState();
    const navigate = useNavigate();
    const [filters, setFilters] = useState({});
    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [metrcisData, setMetricsData] = useState({
        candidatesMetrics: [],
    });
    const [activeCard, setActiveCard] = useState('');

    const getAllMetrics = () => {
        console.log(user)
        // if user is admin or super admin then show all the metrics
        if (user.profileType === 'ADMIN' || user.profileType === 'SUPER') {
            getAdminDashboardMetrics().then((response) => {
                setMetricsData(response.data)
            }).catch((err) => {
                console.error(err);
            });
        }else{
            getCustomerDashboardMetrics(filters).then((response) => {
                setMetricsData(response.data)
                console.log(response.data)
            }).catch((err) => {
                console.error(err);
            });
        }
    }

    const handleOpenFilterModal = () => {
        setFilterModalOpen(true);
    };

    const handleCloseFilterModal = () => {
        setFilterModalOpen(false);
    };

    const handleApplyFilters = (newFilters) => {
        if (['all', 'CREATED'].includes(newFilters.status)) {
            newFilters.status = '';
        }

        if (['UNASSIGNED'].includes(newFilters.status)) {
            delete newFilters.status;
            newFilters.assigneeName = 'UNASSIGNED';
        }

        if (['UNCLAIMED'].includes(newFilters.status)) {
            delete newFilters.status;
            newFilters.panelName = 'UNCLAIMED';
        }


        const updatedFilters = Object.fromEntries(Object.entries(newFilters).filter(([key, value]) => value !== ""));
        setFilters(updatedFilters);
    };
    const filterBasedOnCards = (status) => {
        // Filter the candidate data based on the selected status
        const filteredData = candidatesData.filter(candidate => candidate.status === status);
        // setSearchStatus(status);
         setActiveCard(status);
        // Update the state with the filtered data
        setFilteredCandidates(filteredData);
    };

    const populateCandidates = () => {
        setDataLoading(true);

        getAssignedUsers().then((res) => {
            const userDict = res?.data.reduce((acc, { userId, firstName, lastName }) => {
              acc[userId] = `${firstName} ${lastName}`;
              return acc;
            }, {});
            getCandidates().then((response) => {
                let candidates = response?.data.sort((a, b) => b.candidateId - a.candidateId);
                for (let i=0; i< candidates.length; i++) {
                    candidates[i]['assigneeFullName'] = userDict[candidates[i]['assigneeId']]
                }

                if (startDate != '' && endDate != '') {
                    candidates = filterDataByDateRange(candidates, startDate, endDate);
                }
                console.log(candidates);
                setCandidatesData(candidates);
                setDataLoading(false);
            }).catch((err) => {
                console.error(err);
            });
        });
    }

    useEffect(() => {
        if (filterOptions) {
            let [status, startDate, endDate] = filterOptions.split('|')
            console.log(status, startDate, endDate)
            setSearchStatus(status);
            setStartDate(startDate || '');
            setEndDate(endDate || '');
        }
        populateCandidates();
        handleApplyFilters({'status': searchStatus})
        getAllMetrics();
    }, [searchStatus]);

    useEffect(() => {
        const filterKeys = Object.keys(filters);
        if (filterKeys.length > 0) {
            const filteredData = candidatesData.filter(candidate =>
                filterKeys.every(key => {
                    if (filters[key] == 'UNASSIGNED') {
                        return candidate['assigneeEmailId'] == '';
                    } else if (filters[key] == 'UNCLAIMED') {
                        return candidate['panelEmailId'] == '';
                    } else {
                        return String(candidate[key]).toLowerCase().includes(String(filters[key]).toLowerCase())
                    }
                })
            );
            setFilteredCandidates(filteredData);
        } else {
            setFilteredCandidates(candidatesData);
        }
    }, [filters, candidatesData]);

    useEffect(() => {
        if (dashboardFilter) {
            const filteredData = candidatesData.filter(candidate => candidate.status === dashboardFilter.toUpperCase());

            setActiveCard(dashboardFilter.toUpperCase());
            setFilteredCandidates(filteredData);
        } else {
            setFilteredCandidates(candidatesData);
        }
    }, [dashboardFilter, candidatesData]);
    const addNewCandidate = () => {
        navigate(`/user/addCandidate`);
    };

    const candidateColumns = [
        {
            dataField: 'jobId',
            text: 'Job ID',
            sort: true,
        },
        {
            dataField: 'jobName',
            text: 'Job Name',
            sort: true,
            style: () => {
                return {
                    minWidth: "200px",
                };
            }
        },
        {
            dataField: 'candidateId',
            text: 'Candidate ID',
            sort: true,
        },
        {
            dataField: 'candidateName',
            text: 'Candidate Name',
            sort: true,
            style: () => {
                return {
                    minWidth: "180px",
                };
            },
            formatter: (cell, row) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <span
                        style={{color: '#3E67B1', cursor:'pointer'}}
                        onClick={() => handleRowClick(row)} // Add the onClick event here
                    >{row.candidateName}</span>
                    <span
                        style={{color: '#3E67B1', cursor:'pointer'}}
                        onClick={async (e) => {
                            e.preventDefault(true)
                            console.log("Clicked", row.candidateResumeId)
                            let documentId = row.candidateResumeId;
                            try {
                              let data = await getCandidateDocumentById(documentId);
                              const blobUrl = URL.createObjectURL(data);
                              const link = document.createElement('a');
                              link.href = blobUrl;
                              link.download = documentId;
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                              URL.revokeObjectURL(blobUrl);
                              toast.success('Downloaded resume successfully.!')
                            } catch (error) {
                              toast.error('Resume not exists!')
                            }
                          }}
                    >
                    <svg  xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                         className="bi bi-download" viewBox="0 0 16 16" style={{marginLeft: '10px',color: '#3E67B1'}}>
                        <path
                            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                        <path
                            d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                    </svg>
                    </span>
                </div>
            ),
        },
        {
            dataField: 'customerCompany',
            text: 'Customer Name',
            sort: true,
            // hidden: (!["ADMIN","SUPER"].includes(user.profileType))
        },
        {
            dataField: 'deliveryUnit',
            text: 'Delivery Unit',
            sort: true,
            hidden: (!["ADMIN","SUPER"].includes(user.profileType))
        },
        {
            dataField: 'skillFamily',
            text: 'Skill Family',
            sort: true,
        },
        {
            dataField: 'primarySkills',
            text: 'Primary Skills',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
        },
        {
            dataField: 'assigneeName',
            text: 'Assignee',
            formatter: (cell, row) => (
                <>
                <button
                    className="btn btn-primary"
                    style={{display: (["ADMIN", "SUPER"].includes(user.profileType) && [null, 0].includes(row.assigneeId)) ? 'inline' : 'None'}}
                    onClick={async () => {
                        updateCandidate(row._id, {
                            jobId: row.jobId,
                            assigneeId: user.userId,
                            assigneeEmailId: user.emailId,
                            assigneeName: user.userName
                        }).then((res) => {
                            populateCandidates();
                            toast.success(`Candidate assigned to ${user.emailId}`);
                        }).catch((error) => {
                            toast.error(error.response.data.message);
                        })
                    }}
                > Assign
                </button>
                <span
                    style={{display: row.assigneeId > 0 ? 'inline' : 'None'}}
                > {row.assigneeFullName}
                </span>
            </>
            ),
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: (cell, row) => (
                <>
                    <i
                        title="Edit Candidate"
                        onClick={() => {
                            if ((hasAllPermissions || userPermissions.includes('EDIT_CANDIDATE'))) {
                                navigate(`/user/editCandidate/${row._id}/${row.customerId}`);
                            }
                        }}
                        style={{
                            opacity: !(hasAllPermissions || userPermissions.includes('EDIT_CANDIDATE')) ? 0.2 : 1,
                            pointerEvents: !(hasAllPermissions || userPermissions.includes('EDIT_CANDIDATE')) ? 'none' : 'auto',
                        }}
                        className="fa fa-light fa-pen-to-square"
                    ></i>
                    <i
                        title="View Candidate"
                        className="fa fa-light fa-eye"
                        onClick={() => {
                            if ((hasAllPermissions || userPermissions.includes('VIEW_CANDIDATE'))) {
                                navigate(`/user/viewCandidate/${row._id}/${row.customerId}`);
                            }
                        }}
                        style={{
                            paddingLeft: "10px",
                            cursor: "pointer",
                            opacity: !(hasAllPermissions || userPermissions.includes('VIEW_CANDIDATE')) ? 0.2 : 1,
                            pointerEvents: !(hasAllPermissions || userPermissions.includes('VIEW_CANDIDATE')) ? 'none' : 'auto',
                        }}
                    ></i>
                    <i
                        title="Delete Candidate"
                        onClick={ async () => {
                        if ((hasAllPermissions || userPermissions.includes('DELETE_CANDIDATE'))) {
                            let isConfirmed = window.confirm('Are you sure you want to delete this candidate?');
                            if(isConfirmed) {
                                try {
                                    await deleteCandidate(row._id);
                                    populateCandidates();
                                    toast.success('Candidate deleted Successfully.!');
                                } catch (err) {
                                    console.error(err);
                                    toast.error('Unable to delete Candidate.');
                                }
                            }
                        }
                        }}
                        style={{
                        color: '#3E67B1',
                        display: ['SUPER', 'ADMIN'].includes(user.profileType) ? 'inline' : 'none',
                        paddingLeft: "10px",
                        opacity: !(hasAllPermissions || userPermissions.includes('DELETE_CANDIDATE')) ? 0.2 : 1,
                        pointerEvents: !(hasAllPermissions || userPermissions.includes('DELETE_CANDIDATE')) ? 'none' : 'auto',
                        }}
                        className="fa fa-light fa-trash-can"
                    ></i>
                    <i
                        className="fa fa-file-pdf"
                        title="Download Feedback"
                        onClick={() => {
                            if (['FEEDBACK PROVIDED', 'CLOSED'].includes(row.status) && (hasAllPermissions || userPermissions.includes('DOWNLOAD_FEEDBACK'))) {
                                const newTab = window.open('', '_blank');
                                newTab.location.href = `/admin/downloadPDF/${row._id}`;
                            } else {
                                toast.warn('Warning: Access Denied');
                            }
                        }}
                        style={{
                            paddingLeft: "10px",
                            cursor: "pointer",
                            opacity: !(['FEEDBACK PROVIDED', 'CLOSED'].includes(row.status) && (hasAllPermissions || userPermissions.includes('VIEW_CANDIDATE'))) ? 0.2 : 1,
                            pointerEvents: !(['FEEDBACK PROVIDED', 'CLOSED'].includes(row.status) && (hasAllPermissions || userPermissions.includes('VIEW_CANDIDATE'))) ? 'none' : 'auto',
                        }}
                    ></i>
                </>
            ),
            style: () => {
                return {
                    font: "Muli",
                    minWidth: "120px",
                    letterSpacing: "0.28px",
                    color: "#3A3A3A",
                    opacity: "1",
                };
            },
        },
    ];
    const options = {
        sizePerPage: 10,
        sizePerPageList: [
            // {text: '5', value: 5},
            {text: '10', value: 10},
            {text: '15', value: 15},
            {text: '20', value: 20},
            {text: '50', value: 50},
        ],
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
        alwaysShowAllBtns: true,
        showTotal: true,
        nextPageText: "Next",
        prePageText: "Prev",
    };

    const filterDict = {
        'customerCompany': 'Customer Company',
        'jobName': 'Job Name',
        'candidateName': 'Candidate Name',
        'primarySkills': 'Primary Skills',
        'assigneeFullName': 'Assignee',
        'status': 'Status',
        'deliveryUnit': 'Delivery Unit'
    }
    const [selectedCandidate, setSelectedCandidate] = useState(null);

    const handleRowClick = (row) => {
        setSelectedCandidate(row);
        console.log('selectedCandidate:', row);
    };

    const handleCloseModal = () => {
        setSelectedCandidate(null); // Clear the selected candidate when closing the modal
    };

    const today = new Date().toISOString().slice(0, 10);

    const downloadCandidates = (filteredData) => {
        const modifiedData = filteredData.map(candidate => ({
        'Customer ID': candidate.customerId,
        'Customer Name': candidate.customerCompany,
        'Customer Email ID': candidate.customerEmailId,

        'Job ID': candidate.jobId,
        'Job Name': candidate.jobName,

        'Candidate ID': candidate.candidateId,
        'Candidate Name': candidate.candidateName,
        'Candidate Email ID': candidate.candidateEmailId,
        'Total Experience': candidate.totalExperience,
        'Skill Family': candidate.skillFamily,
        'Primary Skills': candidate.primarySkills,
        'Secondary Skills': candidate.secondarySkills,
        'Highest Qualification': candidate.highestQualification,

        'SlotDate1': candidate.slotDate1,
        'SlotTime1': candidate.slotTime1,
        'Timezone1': candidate.timezone1,
        'SlotDate2': candidate.slotDate2,
        'SlotTime2': candidate.slotTime2,
        'Timezone2': candidate.timezone2,

        'Interview Duration': candidate.interviewDuration,
        'Assignee Name': candidate.assigneeFullName,
        'Assignee Email ID': candidate.assigneeEmailId,
        'Country': candidate.currentCountry,
        'Status': candidate.status,
        }));
        const csv = Papa.unparse(modifiedData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Candidates.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    }

    if (hasAllPermissions || userPermissions.includes('LIST_CANDIDATES')) {

        return (
            <div className="container-page">
                <div className="row shadow-radius"
                     style={{
                         backgroundColor: "#FFFFFF",
                         padding: "20px 20px 0px 20px",
                         marginBottom: "20px",
                         marginRight: "0px",
                         marginLeft: "0px",

                     }}
                >
                    {metrcisData.candidatesMetrics.map((item, index) => (
                        // if item.name ==="Feedback Pending orFeedback Provided" make class name col-2
                        <div
                            className={`table-card-filters ${item.name === 'Feedback Pending' || item.name === 'Feedback Provided' ? 'col-2' : 'col-1'} ${activeCard === item.status ? 'btn btn-primary' : ''}`}
                            style={{
                                // display: item.name === 'Un Assigned' || item.name === 'Un Claimed'  ? 'none' : 'inherit',
                                border: activeCard === item.status ? 'none' : 'inherit'
                            }}

                            onClick={() => filterBasedOnCards(item.status)}

                            key={index}>
                            <div>{item.name}</div>
                            <div
                                style={{
                                    color: item.status === activeCard ? '#ffffff' : 'inherit'
                                }}
                            >{item.value}</div>
                        </div>
                    ))}
                </div>
                <div className="row "
                     style={{
                         marginBottom: "20px",
                     }}
                >
                    <div className="col-3">
                        <label><h6>Start Date</h6></label>
                        <input
                            type="date"
                            id="startDate"
                            value={startDate}
                            max={today}
                            className="form-control"
                            onChange={(e) => setStartDate(e.target.value)}
                            style={{
                                height: "50px",
                                background: "#FFFFFF 0% 0% no-repeat padding-box",
                                border: "1px solid #C7C7C7",
                                borderRadius: "6px",
                                opacity: "1",
                            }}
                            placeholder="Start Date"
                        />
                    </div>
                    <div className="col-3">
                        <label><h6>End Date</h6></label>
                        <input
                            type="date"
                            id="endDate"
                            value={endDate}
                            // min={startDate}
                            max={today}
                            className="form-control"
                            onChange={(e) => setEndDate(e.target.value)}
                            style={{
                                height: "50px",
                                background: "#FFFFFF 0% 0% no-repeat padding-box",
                                border: "1px solid #C7C7C7",
                                borderRadius: "6px",
                                opacity: "1",
                            }}
                            placeholder="End Date"
                        />
                    </div>
                    <div className="col-2">
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                console.log(startDate, endDate)
                                if (startDate != '' && endDate != '') {
                                    const filteredData = filterDataByDateRange(filteredCandidatesData, startDate, endDate)
                                    setCandidatesData(filteredData)
                                    downloadCandidates(filteredData)
                                } else {
                                    setCandidatesData(filteredCandidatesData)
                                    downloadCandidates(filteredCandidatesData)
                                }
                            }}
                            style={{
                                height: "50px",
                                width: "150px",
                                background: "#3E67B1 0% 0% no-repeat padding-box",
                                borderRadius: "4px",
                                opacity: "1",
                                marginTop: "30px"
                            }}
                        >
                            Download
                        </button>
                    </div>
                    <div className="col-4" style={{
                        textAlign: "right",
                        justifyContent: "flex-end",
                        alignContent: "flex-end",
                        paddingRight: "10px"
                    }}>
                        <button
                            className="filters"
                            variant="contained"
                            color="primary"
                            onClick={handleOpenFilterModal}
                        >
                            &nbsp;
                            <span className="filter-icon">
                      <img src="/Images/Filter.svg"/>
                    </span>
                            &nbsp;&nbsp;
                            <span className="filter-text">Filters</span>
                        </button>
                        <button
                            style={{
                                display: (hasAllPermissions || userPermissions.includes('CREATE_CANDIDATE')) ? 'inline' : 'none',
                                paddingInline: "10px",
                                marginLeft: "10px",

                            }}
                            onClick={addNewCandidate}
                            className="create-candidate">
                            Create Candidate
                        </button>
                    </div>
                </div>


                <div
                    className="main-content shadow-radius"
                >
                    <div className="applied-filters"
                         style={{display: Object.keys(filters).length > 0 ? 'block' : 'none'}}>
                        <div className="applied-filters-text">
                            <h4>Applied Filters ({Object.keys(filters).length})</h4>
                        </div>
                        <br></br>
                        <div className="applied-filters-list ">
                            {Object.keys(filters).map((d, i) => {
                                return (filters[d] != '') ? <div>
                  <span className="applied-filter">
                    <label key={i}>{filterDict[d]}:- {filters[d]} &nbsp;{"  "}</label>
                    <i
                        className="fa fa-thin fa-circle-xmark"
                        style={{color: "#3c77dd"}}
                        onClick={() => {
                            console.log('removeFilter called with:', d, filters);
                            const newFilters = {...filters};
                            delete newFilters[d];
                            console.log("updatedFilters::", newFilters)
                            setFilters(newFilters);
                            handleApplyFilters(newFilters);
                        }}
                    ></i>
                  </span>
                                </div> : <></>
                            })}
                        </div>
                        <br/>
                    </div>
                    <div className="custom-table-container "
                    >

                        <BootstrapTable
                            hover
                            bootstrap4
                            keyField="candidateId"
                            data={filteredCandidatesData}
                            columns={candidateColumns}
                            pagination={paginationFactory(options)}
                            // rowEvents={{onClick: (e, row) => handleRowClick(row)}} // Handle row click event

                            noDataIndication={
                                dataLoading ?
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        width: '100%'

                                    }}>
                                        <CircularProgress/>
                                    </Box>
                                    :
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        width: '100%'
                                    }}>
                                        <span>No Data Found</span>
                                    </Box>
                            }
                        />
                        {/* Candidate Modal */}
                        {selectedCandidate && (
                            <CandidateModal
                                open={selectedCandidate !== null}
                                candidate={selectedCandidate}
                                handleClose={handleCloseModal} // Pass the handleClose function to the modal
                            />
                        )}

                        {
                            filterModalOpen ? <FilterModal
                                filters={filters}
                                open={filterModalOpen}
                                handleClose={handleCloseFilterModal}
                                handleApply={handleApplyFilters}
                            /> : <></>
                        }
                    </div>
                </div>
            </div>
        );

    } else {
        return (
            <>
                <div className="container d-flex justify-content-center align-items-center"
                     style={{marginTop: "100px"}}>
                    <div className="card">
                        <div className="card-body text-center">
                            <h2 className="card-title">
                                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                                Access Denied
                            </h2>
                            <p className="card-text">You do not have the required permissions to access this page.</p>
                            <p className="card-text">Please contact your administrator for assistance.</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
