import React, { useRef, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Box from "@mui/material/Box";

import "./Job.css";
import { 
  getCustomerDeliveryUnits, getCountries, getSkillFamilies,getAllSkills,getSkillsBySkillFamily,
  getCustomers,
  updateJob, getJob, getAssignedUsers,
  getSkillAssessments, updateSkillAssessments,
  updatePanelsInJob, removePanelFromJob,
  getVendorsForCustomer, updateVendorsInJob, removeVendorFromJob, requestPanels

} from '../../service/api';

import { toast } from "react-toastify";
import moment from 'moment-timezone';
// import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

export default function EditJob() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');
  
  const { jobId, customerId } = useParams();
  const [delUnits, setDelUnits] = useState([]);
  const [countries, setCountries] = useState([]);
  const [skillFamilies, setSkillFamilies] = useState([]);
  const [skills, setSkills] = useState([]);
  const [customerVendors, setCustomerVendors] = useState([]);
  const [customersData, setCustomers] = useState([]);
  const [customerCoordinatorsData, setCustomerCoordinators] = useState([]);
  const [users, setUsers] = useState([]);

  const formRef = useRef(null);
  const navigate = useNavigate();

  let keyLabels = {
    'accountName' : 'Account Name',
    'lineOfBusiness' : 'Line Of Business', 
    'vertical' : 'Vertical', 
    'deliveryUnit' : 'Delivery Unit', 
    'sourcing' : 'Sourcing',
    'custCoordinatorEmailId' : 'Customer Admin Email ID',
    'custCoordinatorName' : 'Customer Admin Name',
    'customerId' : 'Customer', 
    'isBonusApplicable' : 'Is Bonus Applicable',
    'rateCard' : 'Rate Card',
    'rateCategory' : 'Rate Category',
    'country' : 'Country', 
    'interviewDuration' : 'Interview Duration', 
    'requiredExperience' : 'Minimum Experience Required',
    'primarySkills' : 'Primary Skills', 
    'skillFamily' : 'Skill Family', 
    'shiftTimings' : 'Work/Shift Timings', 
    'shiftType' : 'Work/Shift Type', 
    'positionsRequired' : 'No Of Positions', 
    'keyResponsibilities' : 'Key Responsibilities',
    'jobDescription' : 'Job Description', 
    'jobName' : 'Job Name', 
  };

  function validateJobData(data){
    let result = {isValid:true,}
    let required_fields = [
      // 'accountName',
      // 'lineOfBusiness', 
      // 'vertical', 
      'sourcing',
      'custCoordinatorEmailId',
      'custCoordinatorName',
      'customerId', 
      'rateCard',
      'rateCategory',
      'country', 
      'interviewDuration', 
      'requiredExperience',
      'primarySkills', 
      'skillFamily', 
      'positionsRequired', 
      'keyResponsibilities',
      'jobDescription', 
      'jobName', 
    ];
    required_fields.forEach(key=>{
      if(!data[key] || data[key] == ''){
        result =  {isValid:false,mgs:`Field Missing ${keyLabels[key]}`}
      }
    })
    return result;
  }

  const [jobObj, setJobData] = useState({
    customerId: 0,
    custCoordinatorId: 0,
    vendorId: 0,
    delUnitId: 0,
    custCoordinatorName: '',
    custCoordinatorEmailId: '',
    customerCompany: '',
    deliveryUnit: '',
    vertical: '',
    lineOfBusiness: '',
    accountName: '',

    jobName: '',
    jobDescription: '',
    positionsRequired: 1,
    keyResponsibilitiess: '',
    rateCategory: 'ratesInIndia',
    rateCard: 'highDemand',
    isBonusApplicable: false,

    skillFamily: '',
    primarySkills: [],
    secondarySkills: [],
    interviewDuration: '30 Mins',
    requiredExperience: 1,
    shiftType: 'Hybrid',
    shiftTimings: '09:00 - 18:00',
    country: "IND",

    sourcing: 'External',
    panels: [],
    vendors: [],
    inviteSendToCandidates: true,
    remarks: '',
    assigneeName: '',
    assigneeEmailId: '',
    assigneeId: 0

  });

  const [skillObj, setSkillObj] = useState({
    skillTopic: '',
    expectedProficiency: 'BEGINNER'
  })

  const [searchPanelObj, setSearchPanelObj ] = useState({
    jobId: jobId,
    searchPanel: ''
  })

  const [searchVendorObj, setSearchVendorObj ] = useState({
    jobId: jobId,
    searchVendor: ''
  })


  let [skillData, setSkillData] = useState([]);

  function actionFormatter(row, cell) {
    return (
      <>
        <i 
        style={{ color: "#3E67B1" }} className="fa fa-light fa-trash-can" 
        onClick={()=>{
          getSkillAssessments(jobId).then(async (res) => {
            let allSkills = res.data.skillsToAssess;
            let filteredSkills = allSkills.filter(e => e.skillTopic !== cell.skillTopic);
            await updateSkillAssessments(jobId, {skillsToAssess: filteredSkills});
            toast.success("Skill Assessment removed from the current Job.")
            refreshSkillData();
          })
        }}></i>
      </>
    );
  }

  function panelActionFormatter(row, cell) {
    return (
      <>
        <i 
        style={{ color: "#3E67B1" }} className="fa fa-light fa-trash-can" 
        onClick={()=>{
          removePanelFromJob({jobId: jobId, panelEmailId: cell.emailId}).then((data) => {
            getJob(jobId).then((res) => {
              res.data.primarySkills = res.data.primarySkills.split(',');
              res.data.secondarySkills = res.data.secondarySkills.split(',');
              setJobData(res.data);
            });
            toast.success(data.message);
          }).catch((err) => {
            toast.error(err.response.data.message);
          });
        }}></i>
      </>
    );
  }

  function vendorActionFormatter(row, cell) {
    return (
      <>
        <i 
        style={{ color: "#3E67B1" }} className="fa fa-light fa-trash-can" 
        onClick={()=>{
          removeVendorFromJob({jobId: jobId, vendorEmailId: cell.emailId}).then((data) => {
            getJob(jobId).then((res) => {
              res.data.primarySkills = res.data.primarySkills.split(',');
              res.data.secondarySkills = res.data.secondarySkills.split(',');
              setJobData(res.data);
            });
            toast.success('Vendor removed from the current Job.');
          }).catch((err) => {
            toast.error(err.response.data.message);
          });
        }}></i>
      </>
    );
  }

  const panelDataColumns = [
    {
      dataField: "emailId",
      text: "Panel Email ID",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "firstName",
      text: "Panel Name",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "experience",
      text: "Experience (Years)",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "skillFamily",
      text: "Skill Family",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "primarySkills",
      text: "Primary Skills",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "slots",
      text: "Panel Slots",
      formatter: (cell, row) => {
        return row.slots.map((slot) => {
          let slotDate = moment.utc(slot, 'YYYY-MM-DD HH:mm');
          let slotInUserTimezone = slotDate.clone().tz(moment.tz.guess()); 
          let currentDate = moment();
          if (!slotInUserTimezone.isBefore(currentDate)) {
            return (<>
              <span className="panel-slots-display">
                <label key={slot} style={{margin: '5px'}}>{slotInUserTimezone.format('YYYY-MM-DD HH:mm')}</label>
              </span>
            </>)
          } else {
            return null;
          }
        })
      },
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },

    {
      dataField: "action",
      text: "Actions",
      formatter: panelActionFormatter,
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "right",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "right",
          opacity: "1",
        };
      },
    },
  ];

  const vendorDataColumns = [
    {
      dataField: "emailId",
      text: "Vendor Email ID",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "firstName",
      text: "Vendor Name",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "vendorCompanyName",
      text: "Vendor Company Name",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: vendorActionFormatter,
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "right",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "right",
          opacity: "1",
        };
      },
    },
  ];

  const skillDataColumns = [
    {
      dataField: "skillTopic",
      text: "Skill Topic",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },
    {
      dataField: "expectedProficiency",
      text: "Expected Proficiency",
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          opacity: "1",
        };
      },

      sort: true,
    },

    {
      dataField: "action",
      text: "Actions",
      formatter: actionFormatter,
      headerStyle: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "right",
          opacity: "1",
        };
      },
      style: () => {
        return {
          font: "  Muli",
          letterSpacing: "0.28px",
          color: "#3A3A3A",
          textAlign: "right",
          opacity: "1",
        };
      },
    },
  ];

  useEffect(() => {
    getAssignedUsers().then((res) => {
      setUsers(res.data);
    });

    getCountries().then((res) => {
      setCountries(res.data);
    });
    getSkillFamilies().then((res) => {
      setSkillFamilies(res.data);
    });
    // getAllSkills().then((res) => {
    //   const skills = res?.data.map(e => ({'label': e.skill, 'value': e.skill}))
    //   setSkills(skills);
    // });

    getCustomers().then((res) => {
      const customers = (res.data).filter( e => e.profileType === 'CUSTOMER').map( cust => {
          return {
            'customerId': parseInt(cust.userId), 
            'companyName': cust.companyName,
            'userName': `${cust.firstName} ${cust.lastName}`,
            'companyName': cust.companyName 
          }
      });
      setCustomers(customers);
      const customerCoordinators = (res.data).filter( e => e.profileType === 'CUSTOMER_ADMIN').map( cust => {
        return {
          'customerId': parseInt(cust.customerId), 
          'userId': parseInt(cust.userId), 
          'name': `${cust.firstName} ${cust?.lastName}`,
          'emailId': cust.emailId
        }
      });
      setCustomerCoordinators(customerCoordinators);
    });

    getVendorsForCustomer(customerId).then((res) => {
      let vendors = res?.data?.data;
      setCustomerVendors(vendors);
    }).catch((err) => {
      console.error(err)
    })

    getSkillAssessments(jobId).then((res) => {
      setSkillData(res.data.skillsToAssess);
    })

   
  }, []);

  useEffect(() => {
    getJob(jobId).then((res) => {
      if (res?.data) {
        res.data.primarySkills = res.data.primarySkills.split(',');
        res.data.secondarySkills = res.data.secondarySkills.split(',');
        let customerId = res.data.customerId;
        setJobData(res.data);
        getCustomerDeliveryUnits(parseInt(customerId)).then((data) => {
          const delUnits = (data).map( d => {
            return {
              'customerId': jobObj.customerId,
              'delUnitId': d.delUnitId, 
              'deliveryUnit': d.deliveryUnit, 
              'vertical': d.vertical,
              'lineOfBusiness': d.lineOfBusiness,
              'accountName': d.accountName,
            }
          });
          setDelUnits(delUnits)
          updateDeliveryUnits(customerId)
        });
        getSkillAssessments(jobId).then((res) => {
          setSkillData(res.data.skillsToAssess);
        });
        if (customerId) {
          updateCustomerCoordinators(customerId);
        }
      }
    });
  }, [jobId]);

  const refreshSkillData = async () => {
    getSkillAssessments(jobId).then((res) => {
      setSkillData(res.data.skillsToAssess);
    })
  }


  const updateCustomerCoordinators = async (customerId) => {
    getCustomers().then((res) => {
      const customerCoordinators = (res.data).filter(
        e => e.customerId === customerId && e.profileType === 'CUSTOMER_ADMIN'
      ).map( cust => {
        return {
          'customerId': parseInt(cust.customerId), 
          'userId': parseInt(cust.userId), 
          'name': `${cust.firstName} ${cust.lastName}`,
          'emailId': cust.emailId
        }
      });
      setCustomerCoordinators(customerCoordinators);
    });
  }

  const updateDeliveryUnits = async (customerId) => {
    getCustomerDeliveryUnits(customerId).then((data) => {
      const delUnits = (data).map( d => {
        return {
          'customerId': customerId,
          'delUnitId': d.delUnitId, 
          'deliveryUnit': d.deliveryUnit, 
          'vertical': d.vertical,
          'lineOfBusiness': d.lineOfBusiness,
          'accountName': d.accountName,
        }
      });
      setDelUnits(delUnits)
    });
  }
  
  const updateSkillsData = async (skillFamily) => {
    if (skillFamily != '') {
      getSkillsBySkillFamily(skillFamily).then((data) => {
        const skills = data?.data.map(e => ({'label': e.skill, 'value': e.skill}))
        setSkills(skills)
      });
    } else {
      setSkills([])
    }
  }

  const handleUpdateJob = async (jobObj) => {
    try {
      jobObj.primarySkills = jobObj.primarySkills.join(',');
      jobObj.secondarySkills = jobObj.secondarySkills.join(',');

      // if (!jobObj.assigneeEmailId || jobObj.assigneeEmailId == '') {
      //   jobObj.assigneeId = user.userId;
      //   jobObj.assigneeEmailId = user.emailId;
      //   jobObj.assigneeName = user.userName;
      // }
      await updateJob(jobObj._id, jobObj);
      toast.success(`Job has been Updated.!`);
      navigate('/user/jobs/all');
    } catch (error) {
      console.error('Error posting job data:', error);
      toast.error(error.response.data.message);
    }
  };


  if (hasAllPermissions || userPermissions.includes('EDIT_JOB')) {

    return (
      <>
        <div className="container-page">
          <div className="main-content" style={{ marginLeft: '20px', marginRight: '20px' }}>
            <form ref={formRef} style={{ marginTop: '54px' }}>
              <div className="job-details" style={{ marginTop: "20px" }}>
                <div className="row" >
                  <div className="col">
                    <label><h6>Job ID *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="" 
                    name="jobId"
                    disabled={true} 
                    value={jobObj.jobId}/>
                  </div>
                  <div className="col">
                    <label><h6>Job Name *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="Enter Job Name" name="jobName" 
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        jobName: event.target.value,
                      }));
                    }}
                    value={jobObj.jobName}/>
                  </div>
                  <div className="col">
                  <label><h6>Status *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }} 
                      name="status"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          status: event.target.value
                        }));
                      }}
                      disabled={(!["ADMIN","SUPER"].includes(user.profileType))}
                      value={jobObj.status}
                      >
                      <option value="CREATED">CREATED</option>
                      <option value="IN PROGRESS">IN PROGRESS</option>
                      <option value="APPROVED">APPROVED</option>
                      <option value="REJECTED">REJECTED</option>
                      <option value="CLOSED">CLOSED</option>
                      
                    </select>
                  </div>
                </div>

                <br></br>
                
                <div className="row" >
                  <div className="col">
                    <label><h6>No Of Positions *</h6></label>
                    <input type="number" className="form-control editable" 
                    placeholder="Enter required positions" name="positionsRequired" 
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        positionsRequired: parseInt(event.target.value),
                      }));
                    }}
                    min={1}
                    value={jobObj.positionsRequired}/>
                  </div>
                  <div className="col">
                    <label><h6>Job Description *</h6></label>
                    <textarea rows="3" className="form-control editable" 
                    placeholder="Enter Job Description" name="jobDescription"
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        jobDescription: event.target.value,
                      }));
                    }}
                    value={jobObj.jobDescription}/>
                  </div>
                  <div className="col">
                    <label><h6>Key Responsibilities *</h6></label>
                    <textarea rows="3" className="form-control editable" 
                    placeholder="Enter Key Responsibilities" name="keyResponsibilities" 
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        keyResponsibilities: event.target.value,
                      }));
                    }}
                    value={jobObj.keyResponsibilities}/>
                  </div>
                </div>

                <br></br>
                
                <div className="row">
                  <div className="col">
                    <label><h6>Skill Family *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }} 
                      name="skillFamily"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          skillFamily: event.target.value,
                        }));
                        updateSkillsData(event.target.value);
                      }}
                      value={jobObj.skillFamily}
                      >
                      <option value="">Select Skill Family</option>
                        {skillFamilies.map((sf) => (
                          <option
                            key={sf.skillFamily}
                            value={sf.skillFamily}
                          >
                            {sf.skillFamily}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col">
                    <label><h6>Primary Skills *</h6></label>
                    <CreatableSelect
                      isMulti
                      value={jobObj.primarySkills != '' ? jobObj.primarySkills.map(skill => ({ value: skill, label: skill })) : null} 
                      onChange={(selectedOptions) => {
                        if (!Array.isArray(selectedOptions)) {
                          return;
                        }
                        const selectedSkills = selectedOptions.map((option) => option.value);
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          primarySkills: selectedSkills,
                        }));
                      }}
                      onCreateOption={(inputValue) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          primarySkills: prevJobData.primarySkills.concat(inputValue),
                        }));
                      }}
                      options={skills}
                    />
                  </div>
                  <div className="col">
                    <label><h6>Secondary Skills</h6></label>
                    <CreatableSelect
                      isMulti
                      value={jobObj.secondarySkills != '' ? jobObj.secondarySkills.map(skill => ({ value: skill, label: skill })) : null} 
                      onChange={(selectedOptions) => {
                        if (!Array.isArray(selectedOptions)) {
                          return;
                        }
                        const selectedSkills = selectedOptions.map((option) => option.value);
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          secondarySkills: selectedSkills,
                        }));
                      }}
                      onCreateOption={(inputValue) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          secondarySkills: prevJobData.secondarySkills.concat(inputValue),
                        }));
                      }}
                      options={skills}
                    />
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <div className="col">
                    <label><h6>Minimum Experience Required *</h6></label>
                    <input type='number' className="form-control editable" 
                    placeholder="Enter Required Exp" name="requiredExperience" 
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        requiredExperience: parseInt(event.target.value),
                      }));
                    }}
                    min={1}
                    value={jobObj.requiredExperience}/>
                  </div>
                  <div className="col">
                    <label><h6>Work/Shift Type</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}  
                      name="shiftType"
                      value={jobObj.shiftType}
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          shiftType: event.target.value,
                        }));
                      }}
                      >
                      <option key='1' value='Remote'>Remote</option>
                      <option key='2' value='Hybrid'>Hybrid</option>
                      <option key='3' value='Work From Office'>Work From Office</option>
                    </select>
                  </div>
                  <div className="col">
                    <label><h6>Work/Shift Timings</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}   
                      name="shiftTimings"
                      value={jobObj.shiftTimings}
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          shiftTimings: event.target.value,
                        }));
                      }}
                      >
                      <option key='1' value='09:00 - 18:00'>09:00 - 18:00</option>
                      <option key='2' value='12:00 - 21:00'>12:00 - 21:00</option>
                      <option key='3' value='14:00 - 23:00'>14:00 - 23:00</option>
                    </select>
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <div className="col">
                    <label><h6>Country *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}   
                      name="country"
                      value={jobObj.country}
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          country: event.target.value,
                        }));
                      }}
                    >
                      <option value="">Select Country</option>
                        {countries.map((country) => (
                          <option
                            key={country.countryCode}
                            value={country.countryCode}
                          >
                            {country.countryName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col">
                  <label><h6>Interview Duration *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}  
                      name="interviewDuration"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          interviewDuration: event.target.value,
                        }));
                      }}
                      value={jobObj.interviewDuration}
                      >
                      <option key={0} value={'30 Mins'}>{'30 Mins'}</option>
                      <option key={1} value={'60 Mins'}>{'60 Mins'}</option>
                    </select>
                  </div>
                  <div className="col">
                    
                  </div>
                </div>
              </div>

              <div className="job-details" 
                style={{ 
                  marginTop: "20px", minHeight:"140px",
                  display: ["ADMIN","SUPER"].includes(user.profileType) ? 'block': 'none'
                }}
              >
                <div className="row">
                  <div className="col">
                    <label><h6>Rate Category *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }} 
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          rateCategory: event.target.value,
                        }));
                      }}
                      name="rateCategory"
                      value={jobObj.rateCategory}
                      >
                      <option key={0} value={'ratesInIndia'}>{'Rates In India'}</option>
                      <option key={1} value={'ratesInUS'}>{'Rates In US'}</option>
                      <option key={2} value={'ratesInternal'}>{'Rates Internal'}</option>
                      <option key={3} value={'ratesExternal'}>{'Rates External'}</option>
                    </select>
                  </div>

                  <div className="col">
                    <label><h6>Rate Card *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }} 
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          rateCard: event.target.value,
                        }));
                      }}
                      name="rateCard"
                      value={jobObj.rateCard}
                      >
                      <option key={0} value={'highDemand'}>{'High Demand'}</option>
                      <option key={1} value={'niche'}>{'Niche'}</option>
                      <option key={2} value={'legacy'}>{'Legacy'}</option>
                      <option key={3} value={'leadership'}>{'Leadership'}</option>
                      <option key={4} value={'others'}>{'Others'}</option>
                    </select>
                  </div>

                  <div className="col">
                    <label><h6>Is Bonus Applicable *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}  
                      name="isBonusApplicable"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          isBonusApplicable: event.target.value,
                        }));
                      }}
                      value={jobObj.isBonusApplicable}
                      >
                      <option key={0} value={true}>Yes</option>
                      <option key={1} value={false}>No</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="job-details" style={{ marginTop: "20px", minHeight:"240px" }}>
                <div className="row">
                <div className="col">
                  <label>
                    <h6>Customer *</h6>
                  </label>
                  <select 
                    className="form-control-lg-8 drop-down" 
                    style={{ width: "100%", height: "50%" }}
                    name="customerId"
                    value={jobObj.customerId}
                    disabled={!['SUPER','ADMIN'].includes(user.profileType)}
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        customerId: parseInt(event.target.value),
                      }));
                      updateCustomerCoordinators(parseInt(event.target.value));
                      updateDeliveryUnits(parseInt(event.target.value));
                    }}
                  >
                      <option value="">Select Customer</option>
                        {customersData.map((c) => (
                          <option
                            key={c.customerId}
                            value={c.customerId}
                          >
                            {c.companyName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col">
                    <label><h6>Customer Admin Name *</h6></label>
                    <select 
                    className="form-control-lg-8 drop-down" 
                    style={{ width: "100%", height: "50%" }}
                    name="custCoordinatorId"
                    value={jobObj.custCoordinatorId}
                    onChange={(event) => {
                      let coordinator = customerCoordinatorsData.filter(e => e.userId == event.target.value)[0];
                      if (coordinator) {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          custCoordinatorId: coordinator.userId,
                          custCoordinatorName: coordinator.name,
                          custCoordinatorEmailId: coordinator.emailId,
                        }));
                      } else {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          custCoordinatorId: 0,
                          custCoordinatorName: user.userName,
                          custCoordinatorEmailId: user.emailId,
                        }));
                      }
                    }}
                  >
                      <option value="">Select Co-Ordinator</option>
                      <option 
                        style={{display: user.profileType == 'CUSTOMER' ? 'inline': 'none'}} 
                        key={jobObj.customerId} value={0}>Self (Customer)
                      </option>
                        {customerCoordinatorsData.map((c) => (
                          <option
                            key={c.emailId}
                            value={c.userId}
                          >
                            {c.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col">
                    <label><h6>Customer Admin Email ID</h6></label>
                    <input 
                      className="form-control editable"disabled="true" 
                      placeholder="Customer Admin Email ID" 
                      name="custCoordinatorEmailId" 
                      value={jobObj.custCoordinatorEmailId} 
                    />
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <div className="col">
                    <label><h6>Sourcing *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }} 
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          sourcing: event.target.value,
                        }));
                      }}
                      name="sourcing"
                      value={jobObj.sourcing}
                      >
                      <option key={0} value={'Internal'}>{'Internal'}</option>
                      <option key={1} value={'External'}>{'External'}</option>
                    </select>
                  </div>

                  <div className="col">
                    <label><h6>Invite to be sent to the candidate</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}  
                      name="inviteSendToCandidates"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          inviteSendToCandidates: event.target.value,
                        }));
                      }}
                      value={jobObj.inviteSendToCandidates}
                      >
                      <option key={0} value={true}>Yes</option>
                      <option key={1} value={false}>No</option>
                    </select>
                  </div>
                  <div className="col"></div>
                </div>
              </div>

              <div className="job-details" style={{ marginTop: "20px", minHeight:"240px" }}>
                <div className="row">
                  <div className="col">
                    <label><h6>Delivery Unit </h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}  
                      name="delUnitId"
                      value={jobObj.delUnitId}
                      onChange={(event) => {
                        let delUnit = delUnits.filter(e => e.delUnitId == event.target.value)[0];
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          delUnitId: delUnit.delUnitId,
                          deliveryUnit: delUnit.deliveryUnit,
                          vertical: delUnit.vertical,
                          lineOfBusiness: delUnit.lineOfBusiness,
                          accountName: delUnit.accountName,
                        }));
                      }}
                      >
                      <option value="">Select Delivery Unit</option>
                        {delUnits.map((bu) => (
                          <option
                            key={bu.delUnitId}
                            value={bu.delUnitId}
                          >
                            {bu.deliveryUnit}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col">
                    <label><h6>Vertical</h6></label>
                    <input className="form-control editable" disabled="true" 
                    placeholder="Vertical" name="vertical" value={jobObj.vertical}/>
                  </div>
                  <div className="col">
                    <label><h6>Line Of Business</h6></label>
                    <input className="form-control editable"disabled="true" 
                    placeholder="Line Of Business" name="lineOfBusiness" value={jobObj.lineOfBusiness}/>
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <div className="col">
                    <label><h6>Account Name</h6></label>
                    <input className="form-control editable" disabled="true" 
                    placeholder="Account Name" name="accountName" value={jobObj.accountName}/>
                  </div>
                  <div className="col">
                  </div>
                  <div className="col">
                  </div>
                </div>
              </div>

              

              <div className="comments-remarks" style={{ marginTop: "20px", padding: "20px" }} >
                <div className="row">
                  <div className="col">
                    <label><h6>Assignee</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}   
                      name="assigneeId"
                      value={jobObj.assigneeId}
                      onChange={(event) => {
                        let assignee = users.filter((d) => d.userId == event.target.value)[0];
                        if (assignee) {
                          setJobData((prevJobData) => ({
                            ...prevJobData,
                            assigneeId: event.target.value,
                            assigneeName: assignee?.username,
                            assigneeEmailId: assignee?.emailId,
                          }));
                        } else {
                          setJobData((prevJobData) => ({
                            ...prevJobData,
                            assigneeId: null,
                            assigneeName: '',
                            assigneeEmailId: '',
                          }));
                        }
                      }}
                    >
                      <option key={0} value={0}>Select Assignee</option>
                      {users.map((user) => (
                        <option key={user.userId} value={user.userId}>
                          {user.firstName} {user.lastName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col">
                    <label><h6>Assignee Email ID</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="" name="assigneeEmailId" 
                    disabled={true}
                    value={jobObj.assigneeEmailId}/>
                  </div>
                  <div className="col">
                    {/* <label><h6>Assignee Name</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="" name="assigneeName" 
                    disabled={true}
                    value={jobObj.assigneeName}/> */}
                  </div>
                

                </div>
                <br></br>
                
                <div className="row">
                  <label><h6>Remarks</h6></label>
                  <div className="col-10">
                    <textarea
                      rows="3"
                      className="form-control editable "
                      placeholder="Enter Remarks"
                      name="remarks"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          remarks: event.target.value,
                        }));
                      }}
                      value={jobObj.remarks}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px', textAlign: 'center', paddingBottom: '5px' }}>
                  <div className="col" style={{ textAlign: 'center' }}>
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2"
                      style={{
                        border: '1px solid #3E67B1',
                        borderRadius: ' 4px',
                        opacity: '1',
                        width: '96px',
                        height: '50px',
                      }}
                      onClick={()=>{
                        navigate(-1);
                      }}
                    >
                      BACK
                    </button>
                    <button
                    onClick={() => {
                      const validation = validateJobData(jobObj);
                      if(!validation.isValid){
                        toast.error(validation.mgs)
                        return;
                      }
                      handleUpdateJob(jobObj);
                    }}
                      type="button"
                      className="btn btn-primary"
                      style={{
                        border: '1px solid #3E67B1',
                        borderRadius: ' 4px',
                        opacity: '1',
                        width: '96px',
                        height: '50px',
                        display: (hasAllPermissions || userPermissions.includes('EDIT_JOB')) ? 'inline' : 'none'
                      }}
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              </div>

              <div className="job-details" 
                style={{ 
                  marginTop: "20px", minHeight: '200px' ,
                  display: ["ADMIN","SUPER"].includes(user.profileType) ? 'block': 'none'
                }}
              >
                
                <div className="row">
                  <label>
                    <h5>Panels </h5>
                  </label>
                  <br></br>
                  <div className="col">
                    <input className="form-control editable" 
                    placeholder="Enter Panel Email ID" 
                    onChange={(event) => {
                      setSearchPanelObj((prev) => ({
                        ...prev,
                        searchPanel: event.target.value,
                      }));
                    }}
                    name="searchPanel" value={searchPanelObj.searchPanel}/>
                  </div>

                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={ async () => {
                        if (searchPanelObj.searchPanel == '') {
                          toast.error('Invalid Panel Email ID');
                        } else {
                          updatePanelsInJob(searchPanelObj).then((data) => {
                            getJob(jobId).then((res) => {
                              res.data.primarySkills = res.data.primarySkills.split(',');
                              res.data.secondarySkills = res.data.secondarySkills.split(',');
                              setJobData(res.data);
                            });
                            toast.success('Panels Updated');
                            setSearchPanelObj({searchPanel: '', jobId: jobId})
                          }).catch((err) => {
                            toast.error(err.response.data.message);
                          });
                        }
                      }}
                      style={{ 
                        height: "50px" ,
                        display: (hasAllPermissions || userPermissions.includes('EDIT_JOB')) ? 'inline' : 'none'
                      }}
                    >
                      Add Panel
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={ async () => {
                        let requestPanelsObj = {
                          jobId: jobObj.jobId,
                          jobName: jobObj.jobName,
                          jobDescription: jobObj.jobDescription,
                          requiredExperience: jobObj.requiredExperience,
                          primarySkills: jobObj.primarySkills.join(',')
                        }
                        requestPanels(requestPanelsObj).then((data) => {
                          toast.success(data.message);
                        }).catch((err) => {
                          toast.error(err.response.data.message);
                        });
                      }}
                      style={{ 
                        height: "50px" , 
                        float: 'right',
                        display: (hasAllPermissions || userPermissions.includes('EDIT_JOB')) ? 'inline' : 'none'
                      }}
                    >
                      Request Panels
                    </button>
                  </div>
                </div> 
                <br></br>
                <div className="row">
                  <div className="col">
                    <BootstrapTable
                      className="table"
                      hover
                      bootstrap4
                      keyField="userId"
                      data={jobObj.panels || []}
                      columns={panelDataColumns}
                      pagination={paginationFactory({
                        sizePerPage: 5,
                        sizePerPageList: [
                          { text: '5', value: 5 },
                          { text: '10', value: 10 },
                          { text: '15', value: 15 },
                          { text: '20', value: 20 },
                          { text: '50', value: 50 },
                        ],
                        hideSizePerPage: false,
                        hidePageListOnlyOnePage: false,
                        alwaysShowAllBtns: true,
                        showTotal: true,
                        nextPageText: "Next",
                        prePageText: "Prev",
                      })}
                      filter={filterFactory()}
                      noDataIndication={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <span>No Data Found</span>
                        </Box>
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="job-details" style={{ marginTop: "20px", minHeight: '200px' }}>
                <div className="row">
                  <label>
                    <h5>Vendors</h5>
                  </label>
                  <br></br>
                  <div className="col">
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}
                      name="searchVendor"
                      value={searchVendorObj.searchVendor}
                      onChange={(event) => {
                        setSearchVendorObj((prev) => ({
                          ...prev,
                          searchVendor: event.target.value,
                        }));
                      }}
                    >
                      <option value="">Select Vendor</option>
                        {customerVendors.map((v) => (
                          <option
                            key={v.emailId}
                            value={v.emailId}
                          >
                            {v.vendorCompanyName}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={ async () => {
                        if (searchVendorObj.searchVendor == '') {
                          toast.error('Invalid Vendor Email ID');
                        } else {
                          console.log("VENDOR.. adding", searchVendorObj)
                          updateVendorsInJob(searchVendorObj).then((data) => {
                            getJob(jobId).then((res) => {
                              res.data.primarySkills = res.data.primarySkills.split(',');
                              res.data.secondarySkills = res.data.secondarySkills.split(',');
                              setJobData(res.data);
                            });
                            toast.success('New Vendor added to the current Job.');
                            setSearchVendorObj({searchVendor: '', jobId: jobId})
                          }).catch((err) => {
                            toast.error(err.response.data.message);
                          });
                        }
                      }}
                      style={{ 
                        height: "50px",
                        display: (hasAllPermissions || userPermissions.includes('EDIT_JOB')) ? 'inline' : 'none' 
                      }}
                    >
                      Add Vendor
                    </button>
                  </div>
                </div> 
                <br></br>
                <div className="row">
                  <div className="col">
                    <BootstrapTable
                      className="table"
                      hover
                      bootstrap4
                      keyField="userId"
                      data={jobObj.vendors || []}
                      columns={vendorDataColumns}
                      pagination={paginationFactory({
                        sizePerPage: 5,
                        sizePerPageList: [
                          { text: '5', value: 5 },
                          { text: '10', value: 10 },
                          { text: '15', value: 15 },
                          { text: '20', value: 20 },
                          { text: '50', value: 50 },
                        ],
                        hideSizePerPage: false,
                        hidePageListOnlyOnePage: false,
                        alwaysShowAllBtns: true,
                        showTotal: true,
                        nextPageText: "Next",
                        prePageText: "Prev",
                      })}
                      filter={filterFactory()}
                      noDataIndication={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <span>No Data Found</span>
                        </Box>
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="job-details" style={{ marginTop: "20px", minHeight: '200px' }}>
                
                <div className="row">
                  <label>
                    <h5>Skill Assessment</h5>
                  </label>
                  <br></br>
                  <div className="col">
                    <label><h6>Topic/Technology *</h6></label>
                    <input className="form-control editable" 
                    placeholder="Enter Skill" 
                    onChange={(event) => {
                      setSkillObj((prev) => ({
                        ...prev,
                        skillTopic: event.target.value,
                      }));
                    }}
                    name="skillTopic" value={skillObj.skillTopic}/>
                  </div>

                  <div className="col">
                    <label><h6>Expected Proficiency *</h6></label>
                      <select 
                        className="form-control-lg-8 drop-down" 
                        style={{ width: "100%", height: "50%" }} 
                        onChange={(event) => {
                          setSkillObj((prev) => ({
                            ...prev,
                            expectedProficiency: event.target.value,
                          }));
                        }}
                        name="expectedProficiency"
                        value={skillObj.expectedProficiency}
                        >
                        {/* <option key={0} value=''>Select Proficiency</option> */}
                        <option key={1} value='BEGINNER'>BEGINNER</option>
                        <option key={2} value='AVERAGE'>AVERAGE</option>
                        <option key={3} value='INTERMEDIATE'>INTERMEDIATE</option>
                        <option key={4} value='GOOD'>GOOD</option>
                        <option key={5} value='ADVANCED'>ADVANCED</option>
                      </select>
                  </div>
                    
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={ async () => {
                        if (!skillObj.skillTopic) {
                          toast.error('Invalid Skill Topic');
                          return;
                        }
                        skillData.push(skillObj)
                        await updateSkillAssessments(jobId, {skillsToAssess: skillData});
                        toast.success("New Skill Assessment added to the current Job.")
                        setSkillObj({skillTopic: '', expectedProficiency: 'BEGINNER'});
                        refreshSkillData();
                      }}
                      style={{ 
                        height: "50px", 
                        marginTop: "30px",
                        display: (hasAllPermissions || userPermissions.includes('EDIT_JOB')) ? 'inline' : 'none'
                      }}
                    >
                      Add Skill Assessment
                    </button>
                  </div>
                </div> 
                <br></br>
                <div className="row">
                  <div className="col">
                    <BootstrapTable
                      className="table"
                      hover
                      bootstrap4
                      keyField="skillTopic"
                      data={skillData}
                      columns={skillDataColumns}
                      pagination={paginationFactory({
                        sizePerPage: 5,
                        sizePerPageList: [
                          { text: '5', value: 5 },
                          { text: '10', value: 10 },
                          { text: '15', value: 15 },
                          { text: '20', value: 20 },
                          { text: '50', value: 50 },
                        ],
                        hideSizePerPage: false,
                        hidePageListOnlyOnePage: false,
                        alwaysShowAllBtns: true,
                        showTotal: true,
                        nextPageText: "Next",
                        prePageText: "Prev",
                      })}
                      filter={filterFactory()}
                      noDataIndication={
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                          <span>No Data Found</span>
                        </Box>
                      }
                    />
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>

      </>
    );
  
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
