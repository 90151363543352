import React, { useRef, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import "./Job.css";
import { 
  getJobs, getCustomerDeliveryUnits, getCountries, getSkillFamilies, getSkillsBySkillFamily,
  getCustomers, getVendors, getDeliveryUnits,
  postJob, uploadJobs, getAllSkills
} from '../../service/api';

import { toast } from "react-toastify";
import Papa from 'papaparse';

// import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

export default function AddJob() {
  const user = JSON.parse(localStorage.getItem('user'));
  const userPermissions = user.permissions.map(e => e.permissionName);
  const hasAllPermissions = userPermissions.includes('ALL_PERMISSIONS');

  const [selectedForm, setSelectedForm] = useState("form1");
  const [delUnits, setDelUnits] = useState([]);
  const [countries, setCountries] = useState([]);
  const [skillFamilies, setSkillFamilies] = useState([]);
  const [skills, setSkills] = useState([]);
  const [vendorsData, setVendors] = useState([]);
  const [customersData, setCustomers] = useState([]);
  const [coordinatorsData, setCoordinators] = useState([]);
  const [customerCoordinatorsData, setCustomerCoordinators] = useState([]);
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState([]);
  // const [skillFamilyData, setSkillFamiliesData] = useState([]);
  // const [skillFamilyDict, setSkillFamiliesDict] = useState({});
  const [filteredJobsData, setFilteredJobs] = useState([]);
  const [dataLoading, setDataLoading] = useState();
  const [search, setSearch] = useState("");
  const MAX_CSV_FILE_SIZE = 1 * 1024 * 1024; // 1MB


  const [bulkCSV, setBulkCSV] = useState(null);
  const formRef = useRef(null);
  const formRef2 = useRef(null);
  const [csvData, setCSVData] = useState([]);
  const [error, setError] = useState(null);

  let keyLabels = {
    'accountName' : 'Account Name',
    'lineOfBusiness' : 'Line Of Business', 
    'vertical' : 'Vertical', 
    'deliveryUnit' : 'Delivery Unit', 
    'sourcing' : 'Sourcing',
    'custCoordinatorEmailId' : 'Customer Admin Email ID',
    'custCoordinatorName' : 'Customer Admin Name',
    'customerId' : 'Customer', 
    'isBonusApplicable' : 'Is Bonus Applicable',
    'rateCard' : 'Rate Card',
    'rateCategory' : 'Rate Category',
    'country' : 'Country', 
    'interviewDuration' : 'Interview Duration', 
    'requiredExperience' : 'Minimum Experience Required',
    'primarySkills' : 'Primary Skills', 
    'skillFamily' : 'Skill Family', 
    'shiftTimings' : 'Work/Shift Timings', 
    'shiftType' : 'Work/Shift Type', 
    'positionsRequired' : 'No Of Positions', 
    'keyResponsibilities' : 'Key Responsibilities',
    'jobDescription' : 'Job Description', 
    'jobName' : 'Job Name', 
  };

  function validateJobData(data){
    let result = {isValid:true,}
    let required_fields = [
      'sourcing',
      'custCoordinatorEmailId',
      'custCoordinatorName',
      'customerId', 
      'rateCard',
      'rateCategory',
      'country', 
      'interviewDuration', 
      'requiredExperience',
      'primarySkills', 
      'skillFamily', 
      'positionsRequired', 
      'keyResponsibilities',
      'jobDescription', 
      'jobName', 
    ];
    required_fields.forEach(key=>{
      if(key == 'isBonusApplicable') {
        if(![true, false].includes(data[key]) ){
          result =  {isValid:false,mgs:`Field Missing ${keyLabels[key]}`}
        }
      } else {
        if(!data[key] || data[key] == ''){
          if (['CUSTOMER'].includes(user.profileType)) {
            if ( !['custCoordinatorName', 'custCoordinatorEmailId'].includes(key)) {
              result =  {isValid:false,mgs:`Field Missing ${keyLabels[key]}`}
            }
          } else {
            result =  {isValid:false,mgs:`Field Missing ${keyLabels[key]}`}
          }
        }
      }
    })
    return result;
  }

  function validateBulkJobData(data){
    let result = {isValid:true,}
    let reqFields = [
      'customerId','jobName','jobDescription',
      'positionsRequired','keyResponsibilities','skillFamily','primarySkills',
      'requiredExperience'
    ];
    for (let i=0; i< data.length; i++) {
      reqFields.forEach(key=>{
        if(!data[i][key]){
          result =  {isValid:false, mgs:`Field Missing ${keyLabels[key]}`}
        }
      })
    }
    return result;
  }

  function validIntegerValues(data){
    let result = {isValid:true,}
    let intFields = [
      'customerId', 'positionsRequired','requiredExperience'
    ];
    for (let i=0; i< data.length; i++) {
      intFields.forEach(key=>{
        if(!Number.isInteger(parseInt(data[i][key]))){
          result =  {isValid:false, mgs:`Invalid Integer Value for ${keyLabels[key]}`}
        }
      })
    }
    return result;
  }

  const [jobObj, setJobData] = useState({
    customerId: user.customerId || 0,
    custCoordinatorId: 0,
    vendorId: 0,
    delUnitId: 0,
    custCoordinatorName: user.customerId > 0 ? user.userName : '',
    custCoordinatorEmailId: user.customerId > 0 ? user.emailId : '',
    customerCompany: '',
    deliveryUnit: '',
    vertical: '',
    lineOfBusiness: '',
    accountName: '',
    jobName: '',
    jobDescription: '',
    rateCategory: 'ratesInIndia',
    rateCard: 'highDemand',
    isBonusApplicable: false,
    positionsRequired: 1,
    keyResponsibilities: '',
    skillFamily: '',
    primarySkills: [],
    secondarySkills: [],
    interviewDuration: '30 Mins',
    requiredExperience: 1,
    shiftType: 'Hybrid',
    shiftTimings: '09:00 - 18:00',
    country: "IND",
    sourcing: 'External',
    vendors: [],
    inviteSendToCandidates: true,
    remarks: '',
  });

  const [templateData, setTemplateData] = useState([
    {
      "customerId": 6,
      "jobName": "BulkJob",
      "jobDescription": "BulkJobDesc",
      "positionsRequired": 10,
      "keyResponsibilities": "Develop Applications etc..",
      "skillFamily": 'Analytics',
      "primarySkills": "Python|Shell|Unix",
      "secondarySkills": "SQL",
      "requiredExperience": 5,
      "sourcing": "External"
    }
  ]);

  const populateJobs = () => {
    setDataLoading(true);
      getJobs().then((response) => {
        setJobsData(response.data.filter(e => ['APPROVED', 'CLOSED'].includes(e.status)));
        setFilteredJobs(response.data.filter(e => ['APPROVED', 'CLOSED'].includes(e.status)));
      });
      setDataLoading(false);
  }

  const downloadJobsTemplate = () => {
    const csv = Papa.unparse(templateData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'jobsTemplate.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  // const [visibleOptions, setVisibleOptions] = useState([]);
  // const loadMoreOptions = () => {
  //   const nextBatch = skills.slice(visibleOptions.length, visibleOptions.length + 100); // Load next 50 options
  //   setVisibleOptions(prevOptions => [...prevOptions, ...nextBatch]);
  // };
  // const customFilterOption = (option, searchText) => {
  //   return (
  //     // option.label.toLowerCase().includes(searchText.toLowerCase()) ||
  //     option.value.toLowerCase().includes(searchText.toLowerCase())
  //   );
  // };

  useEffect(() => {
    populateJobs();
    getCountries().then((res) => {
      setCountries(res.data);
    });
    getSkillFamilies().then((res) => {
      setSkillFamilies(res.data);
    });
    // getAllSkills().then((res) => {
      // const skills = res?.data.map(e => ({'label': e.skill, 'value': e.skill}));
      // setVisibleOptions(skills.slice(0, 50))
      // setSkills(skills);
    // });
    getCustomers().then((res) => {
      const customers = (res.data).filter( e => e.profileType === 'CUSTOMER').map( cust => {
          return {
            'customerId': parseInt(cust.userId), 
            'userId': parseInt(cust.userId), 
            'userName': `${cust.firstName} ${cust.lastName}`,
            'companyName': cust.companyName 
          }
      });
      let coordinators = (res.data).filter( e => e.profileType === 'CUSTOMER_ADMIN').map( cust => {
        return {
          'customerId': parseInt(cust.customerId), 
          'userId': parseInt(cust.userId), 
          'name': `${cust.firstName} ${cust.lastName}`,
          'emailId': cust.emailId
        }
      })
      if (user.customerId) {
        coordinators = coordinators.filter(e => e.customerId == user.customerId);
        setCustomerCoordinators(coordinators)
      }
      setCustomers(customers);
      setCoordinators(coordinators);
      if (jobObj?.customerId) {
        updateCustomerCoordinators(jobObj.customerId);
      }
    });
    
    getDeliveryUnits().then((data) => {
      const delUnits = (data).map( d => {
        return {
          'customerId': d.customerId,
          'delUnitId': d.delUnitId, 
          'deliveryUnit': d.deliveryUnit, 
          'vertical': d.vertical,
          'lineOfBusiness': d.lineOfBusiness,
          'accountName': d.accountName,
        }
      });
      setDelUnits(delUnits)
      if (user?.customerId) {
        updateDeliveryUnits(user.customerId);
      }
    });
  }, []);

  useEffect(() => {
    setFilteredJobs(
      // jobsData.filter((job) =>
      //   Object.values(job).some(
      //     (value) => {
      //       if(value) {
      //         return value.toString().toLowerCase().includes(search.toLowerCase()) 
      //       } else {
      //         return false;
      //       }
      //     }
      //   )
      // )
      jobsData.filter((job) =>
        job.jobName.toLowerCase().includes(search.trim().toLowerCase()) ||
        job.customerCompany.toLowerCase().includes(search.trim().toLowerCase()) ||
        job.primarySkills.toLowerCase().includes(search.trim().toLowerCase())
      )
    );
  }, [search, jobsData]);
  

  const updateCustomerCoordinators = async (customerId) => {
    getCustomers().then((res) => {
      const customerCoordinators = (res.data).filter(
        e => e.customerId === customerId && e.profileType === 'CUSTOMER_ADMIN'
      ).map( cust => {
        return {
          'customerId': parseInt(cust.customerId), 
          'userId': parseInt(cust.userId), 
          'name': `${cust.firstName} ${cust.lastName}`,
          'emailId': cust.emailId
        }
      });
      setCustomerCoordinators(customerCoordinators);
    });
  }

  const updateDeliveryUnits = async (customerId) => {
    getCustomerDeliveryUnits(customerId).then((data) => {
      const delUnits = (data).map( d => {
        return {
          'customerId': customerId,
          'delUnitId': d.delUnitId, 
          'deliveryUnit': d.deliveryUnit, 
          'vertical': d.vertical,
          'lineOfBusiness': d.lineOfBusiness,
          'accountName': d.accountName,
        }
      });
      setDelUnits(delUnits)
    });
  }

  const handleCreateJob = async (jobObj) => {
    try {
      jobObj.primarySkills = jobObj?.primarySkills.join(',');
      jobObj.secondarySkills = jobObj?.secondarySkills.join(',');

      if (['CUSTOMER'].includes(user.profileType)) {
        if (jobObj.custCoordinatorId == 0) {
          jobObj.custCoordinatorName = user.userName;
          jobObj.custCoordinatorEmailId = user.emailId;
        }
      }

      const response = await postJob(jobObj);
      toast.success(response.message);
      navigate('/user/jobs/all');
    } catch (error) {
      console.error('Error posting job data:', error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleFormSelection = (event) => {
    setSelectedForm(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.size > MAX_CSV_FILE_SIZE) {
      toast.error('File size exceeds the maximum allowed size of 1 MB.');
      setBulkCSV(null);
      setCSVData([]);
    } else {
      if (file) {
        setBulkCSV(file);
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            console.log(result.data)
            setCSVData(result.data);
          },
          error: (err) => {
            console.log(err);
            setError(err.message);
          },
        });
      }
    }
  };

  const handleUploadJob = async (jobsArray) => {
    try {
      const response = await uploadJobs(jobsArray);
      toast.success(response.message);
      navigate('/user/jobs/all');
    } catch (error) {
      console.error('Error uploading jobs data:', error);
      toast.error(error.response.data.message);
    }
  };

  const jobsColumns = [
    {
      dataField: 'jobName',
      text: 'Job Name',
      sort: true,
    },
    {
      dataField: 'customerCompany',
      text: 'Customer Name',
      sort: true,
    },
    {
      dataField: 'primarySkills',
      text: 'Primary Skills',
      sort: true,
    },
    // {
    //   dataField: 'status',
    //   text: 'Status',
    //   sort: true,
    // },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row) => (
        <>
          <button
            type="button"
            data-bs-dismiss="modal"
            style={{
              border: '0px',
              background: 'transparent'
            }}
          >
            <img 
            src="/Images/select.svg" 
            style={{ cursor: 'pointer'}}
            onClick={() => {
              let selectedJob = jobsData.filter(e => e.jobId == row.jobId)[0];

              console.log(selectedJob)
              setJobData({
                customerId: user.customerId || 0,
                custCoordinatorId: 0,
                vendorId: 0,
                delUnitId: 0,
                custCoordinatorName: '',
                custCoordinatorEmailId: '',
                customerCompany: '',
                rateCategory: selectedJob.rateCategory || 'ratesInIndia',
                rateCard: selectedJob.rateCard || 'highDemand',
                isBonusApplicable: false,
                deliveryUnit: '',
                vertical: '',
                lineOfBusiness: '',
                accountName: '',
                jobName: selectedJob.jobName,
                jobDescription: selectedJob.jobDescription,
                positionsRequired: selectedJob.positionsRequired,
                keyResponsibilities: selectedJob.keyResponsibilities,
                skillFamily: selectedJob.skillFamily,
                primarySkills: selectedJob.primarySkills.split(','),
                secondarySkills: selectedJob.secondarySkills.split(','),
                interviewDuration: '30 Mins',
                requiredExperience: selectedJob.requiredExperience,
                shiftType: selectedJob.shiftType,
                shiftTimings: selectedJob.shiftTimings,
                country: selectedJob.country,
                sourcing: selectedJob.sourcing,
                vendors: [],
                inviteSendToCandidates: true,
                remarks: '',
              })
              toast.success('Template Selected.!');
            }}
            />
          </button>
        </>
      ),
    },
  ];

  const options = {
    sizePerPage: 10,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '50', value: 50 },
    ],
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    alwaysShowAllBtns: true,
    showTotal: true,
    nextPageText: "Next",
    prePageText: "Prev",
  };

  const updateSkillsData = async (skillFamily) => {
    if (skillFamily != '') {
      getSkillsBySkillFamily(skillFamily).then((data) => {
        const skills = data?.data.map(e => ({'label': e.skill, 'value': e.skill}))
        setSkills(skills)
      });
    } else {
      setSkills([])
    }
  }


  if (hasAllPermissions || userPermissions.includes('CREATE_JOB')) {

    return (
      <>
        <div className="container-page" >
          <div className="main-content" style={{ marginLeft: '20px', marginRight: '20px' }}>
            
            <div className="row justify-content-center">
              <div className="col-auto d-flex align-items-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    value='form1'
                    checked={selectedForm === 'form1'}
                    onChange={handleFormSelection}
                  />
                    Create Job Description
                </div>
              </div>
              <div className="col-auto d-flex align-items-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    value='form2'
                    checked={selectedForm === 'form2'}
                    onChange={handleFormSelection}
                  />
                    Bulk Jobs Upload
                </div>
                <div className="col-auto d-flex align-items-center">
                  <div className="col" style={{ textAlign: "right", margin: "20px" }}>
                    <button
                      className="select-template"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal-1"
                      style={{
                        width: '150px',
                        display: selectedForm == 'form1' ? 'inline': 'none'
                      }}
                    >
                      Select Template &nbsp;
                      {/* <img src="/Images/doc.svg" alt="" /> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* SingleForm */}
            <form ref={formRef} style={{ marginTop: '54px', display: selectedForm == 'form1' ? 'inline': 'none' }}>
              <div className="job-details" style={{ marginTop: "20px" }}>
                <div className="row" >
                  <div className="col">
                    <label><h6>Job Name *</h6></label>
                    <input type="text" className="form-control editable" 
                    placeholder="Enter Job Name" name="jobName" 
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        jobName: event.target.value,
                      }));
                    }}
                    value={jobObj.jobName}/>
                  </div>
                  <div className="col">
                    <label><h6>Job Description *</h6></label>
                    <textarea rows="3" className="form-control editable" 
                    placeholder="Enter Job Description" name="jobDescription"
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        jobDescription: event.target.value,
                      }));
                    }}
                    value={jobObj.jobDescription}/>
                  </div>
                  <div className="col">
                    <label><h6>Key Responsibilities *</h6></label>
                    <textarea rows="3" className="form-control editable" 
                    placeholder="Enter Key Responsibilities" name="keyResponsibilities" 
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        keyResponsibilities: event.target.value,
                      }));
                    }}
                    value={jobObj.keyResponsibilities}/>
                  </div>
                </div>

                <br></br>
                
                <div className="row" >
                  <div className="col">
                    <label><h6>No Of Positions *</h6></label>
                    <input type="number" className="form-control editable" 
                    placeholder="Enter required positions" name="positionsRequired" 
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        positionsRequired: parseInt(event.target.value),
                      }));
                    }}
                    min={1}
                    value={jobObj.positionsRequired}/>
                  </div>
                  <div className="col">
                    <label><h6>Work/Shift Type</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}  
                      name="shiftType"
                      value={jobObj.shiftType}
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          shiftType: event.target.value,
                        }));
                      }}
                      >
                      <option key='1' value='Remote'>Remote</option>
                      <option key='2' value='Hybrid'>Hybrid</option>
                      <option key='3' value='Work From Office'>Work From Office</option>
                    </select>
                  </div>
                  <div className="col">
                    <label><h6>Work/Shift Timings</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}   
                      name="shiftTimings"
                      value={jobObj.shiftTimings}
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          shiftTimings: event.target.value,
                        }));
                      }}
                      >
                      <option key='1' value='09:00 - 18:00'>09:00 - 18:00</option>
                      <option key='2' value='12:00 - 21:00'>12:00 - 21:00</option>
                      <option key='3' value='14:00 - 23:00'>14:00 - 23:00</option>
                    </select>
                  </div>
                </div>

                <br></br>
                
                <div className="row">
                  <div className="col">
                    <label><h6>Skill Family *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }} 
                      name="skillFamily"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          skillFamily: event.target.value,
                        }));
                        updateSkillsData(event.target.value);
                      }}
                      value={jobObj.skillFamily}
                      >
                      <option value="">Select Skill Family</option>
                        {skillFamilies.map((sf) => (
                          <option
                            key={sf.skillFamily}
                            value={sf.skillFamily}
                          >
                            {sf.skillFamily}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col">
                    <label><h6>Primary Skills *</h6></label>
                    <CreatableSelect
                      isMulti
                      value={jobObj.primarySkills != '' ? jobObj.primarySkills.map(skill => ({ value: skill, label: skill })) : null} 
                      onChange={(selectedOptions) => {
                        if (!Array.isArray(selectedOptions)) {
                          return;
                        }
                        const selectedSkills = selectedOptions.map((option) => option.value);
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          primarySkills: selectedSkills,
                        }));
                      }}
                      onCreateOption={(inputValue) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          primarySkills: prevJobData.primarySkills.concat(inputValue),
                        }));
                      }}
                      options={skills}
                      // onMenuScrollToBottom={loadMoreOptions} 
                      // filterOption={customFilterOption}
                    />
                  </div>
                  <div className="col">
                    <label><h6>Secondary Skills</h6></label>
                    <CreatableSelect
                      isMulti
                      value={jobObj.secondarySkills != '' ? jobObj.secondarySkills.map(skill => ({ value: skill, label: skill })) : null} 
                      onChange={(selectedOptions) => {
                        if (!Array.isArray(selectedOptions)) {
                          return;
                        }
                        const selectedSkills = selectedOptions.map((option) => option.value);
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          secondarySkills: selectedSkills,
                        }));
                      }}
                      onCreateOption={(inputValue) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          secondarySkills: prevJobData.secondarySkills.concat(inputValue),
                        }));
                      }}
                      options={skills}
                    />
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <div className="col">
                    <label><h6>Minimum Experience Required *</h6></label>
                    <input type='number' className="form-control editable" 
                    placeholder="Enter Required Exp" name="requiredExperience" 
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        requiredExperience: parseInt(event.target.value),
                      }));
                    }}
                    min={1}
                    value={jobObj.requiredExperience}/>
                  </div>
                  <div className="col">
                    <label><h6>Interview Duration *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}  
                      name="interviewDuration"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          interviewDuration: event.target.value,
                        }));
                      }}
                      value={jobObj.interviewDuration}
                      >
                      <option key={0} value={'30 Mins'}>{'30 Mins'}</option>
                      <option key={1} value={'60 Mins'}>{'60 Mins'}</option>
                    </select>
                  </div>
                  <div className="col">
                    <label><h6>Country *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}   
                      name="country"
                      value={jobObj.country}
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          country: event.target.value,
                        }));
                      }}
                    >
                      <option value="">Select Country</option>
                        {countries.map((country) => (
                          <option
                            key={country.countryCode}
                            value={country.countryCode}
                          >
                            {country.countryName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

              </div>

              <div className="job-details" 
                style={{ 
                  marginTop: "20px", minHeight:"140px",
                  display: ["ADMIN","SUPER"].includes(user.profileType) ? 'block': 'none'
                }}
                >
                <div className="row">
                  <div className="col">
                    <label><h6>Rate Category *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }} 
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          rateCategory: event.target.value,
                        }));
                      }}
                      name="rateCategory"
                      value={jobObj.rateCategory}
                      >
                      <option key={0} value={'ratesInIndia'}>{'Rates In India'}</option>
                      <option key={1} value={'ratesInUS'}>{'Rates In US'}</option>
                      <option key={2} value={'ratesInternal'}>{'Rates Internal'}</option>
                      <option key={3} value={'ratesExternal'}>{'Rates External'}</option>
                    </select>
                  </div>

                  <div className="col">
                    <label><h6>Rate Card *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }} 
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          rateCard: event.target.value,
                        }));
                      }}
                      name="rateCard"
                      value={jobObj.rateCard}
                      >
                      <option key={0} value={'highDemand'}>{'High Demand'}</option>
                      <option key={1} value={'niche'}>{'Niche'}</option>
                      <option key={2} value={'legacy'}>{'Legacy'}</option>
                      <option key={3} value={'leadership'}>{'Leadership'}</option>
                      <option key={4} value={'others'}>{'Others'}</option>
                    </select>
                  </div>

                  <div className="col">
                    <label><h6>Is Bonus Applicable *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}  
                      name="isBonusApplicable"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          isBonusApplicable: event.target.value,
                        }));
                      }}
                      value={jobObj.isBonusApplicable}
                      >
                      <option key={0} value={true}>Yes</option>
                      <option key={1} value={false}>No</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="job-details" style={{ marginTop: "20px", minHeight:"240px" }}>
                <div className="row">
                <div className="col">
                  <label>
                    <h6>Customer *</h6>
                  </label>
                  <select 
                    className="form-control-lg-8 drop-down" 
                    style={{ width: "100%", height: "50%" }}
                    name="customerId"
                    value={jobObj.customerId}
                    disabled={!['SUPER','ADMIN'].includes(user.profileType)}
                    onChange={(event) => {
                      setJobData((prevJobData) => ({
                        ...prevJobData,
                        customerId: parseInt(event.target.value),
                      }));
                      updateCustomerCoordinators(parseInt(event.target.value));
                      updateDeliveryUnits(parseInt(event.target.value));
                    }}
                  >
                      <option value="">Select Customer</option>
                        {customersData.map((c) => (
                          <option
                            key={c.customerId}
                            value={c.customerId}
                          >
                            {c.companyName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col"
                    style={{
                      display: user.profileType != 'CUSTOMER' ? 'inline': 'none'
                    }} 
                  >
                    <label><h6>Customer Admin Name *</h6></label>
                    <select 
                    className="form-control-lg-8 drop-down" 
                    style={{ width: "100%", height: "50%" }}
                    name="custCoordinatorId"
                    value={jobObj.custCoordinatorId}
                    onChange={(event) => {
                      let coordinator = customerCoordinatorsData.filter(e => e.userId == event.target.value)[0];
                      if (coordinator) {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          custCoordinatorId: coordinator.userId,
                          custCoordinatorName: coordinator.name,
                          custCoordinatorEmailId: coordinator.emailId,
                        }));
                      } else {
                        let customer = customersData.filter(e => e.userId == jobObj.customerId)[0];
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          custCoordinatorId: 0,
                          custCoordinatorName: customer.userName,
                          custCoordinatorEmailId: customer.emailId,
                        }));
                      }
                    }}
                  >
                      <option value="">Select Customer Admin</option>
                        {customerCoordinatorsData.map((c) => (
                          <option
                            key={c.emailId}
                            value={c.userId}
                          >
                            {c.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col"
                    style={{
                      display: user.profileType == 'CUSTOMER' ? 'inline': 'none'
                    }} 
                  >
                    <label><h6>Customer Admin Name *</h6></label>
                    <select 
                    className="form-control-lg-8 drop-down" 
                    style={{ width: "100%", height: "50%" }}
                    name="custCoordinatorId"
                    value={jobObj.custCoordinatorId}
                    onChange={(event) => {
                      let coordinator = customerCoordinatorsData.filter(e => e.userId == event.target.value)[0];
                      if (coordinator) {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          custCoordinatorId: coordinator.userId,
                          custCoordinatorName: coordinator.name,
                          custCoordinatorEmailId: coordinator.emailId,
                        }));
                      } else {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          custCoordinatorId: 0,
                          custCoordinatorName: user.userName,
                          custCoordinatorEmailId: user.emailId,
                        }));
                      }
                    }}
                  >
                      <option value="">Select Customer Admin</option>
                      <option 
                        style={{display: user.profileType == 'CUSTOMER' ? 'inline': 'none'}} 
                        key={jobObj.customerId} value={0}>Self (Customer)
                      </option>
                        {customerCoordinatorsData.map((c) => (
                          <option
                            key={c.emailId}
                            value={c.userId}
                          >
                            {c.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col"
                  style={{
                    display: user.profileType != 'CUSTOMER' ? 'inline': 'none'
                  }} 
                  >
                    <label><h6>Customer Admin Email ID</h6></label>
                    <input 
                      className="form-control editable"disabled="true" 
                      placeholder="Customer Admin Email ID" 
                      name="custCoordinatorEmailId" 
                      value={ jobObj.custCoordinatorEmailId } 
                    />
                  </div>
                  <div className="col"
                  style={{
                    display: user.profileType == 'CUSTOMER' ? 'inline': 'none'
                  }} 
                  >
                    <label><h6>Customer Admin Email ID</h6></label>
                    <input 
                      className="form-control editable" 
                      disabled="true" 
                      placeholder="Customer Admin Email ID" 
                      name="custCoordinatorEmailId" 
                      value={ 
                        jobObj.custCoordinatorId != 0 ? jobObj.custCoordinatorEmailId : user.emailId
                      } 
                    />
                  </div>
                </div>

                <br></br>

                <div className="row">
                  
                  <div className="col">
                    <label><h6>Sourcing *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }} 
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          sourcing: event.target.value,
                        }));
                      }}
                      name="sourcing"
                      value={jobObj.sourcing}
                      >
                      <option key={0} value={'Internal'}>{'Internal'}</option>
                      <option key={1} value={'External'}>{'External'}</option>
                    </select>
                  </div>

                  <div className="col">
                    <label><h6>Invite to be sent to the candidate</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}  
                      name="inviteSendToCandidates"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          inviteSendToCandidates: event.target.value,
                        }));
                      }}
                      value={jobObj.inviteSendToCandidates}
                      >
                      <option key={0} value={true}>Yes</option>
                      <option key={1} value={false}>No</option>
                    </select>
                  </div>
                  <div className="col">
                    {/* <label><h6>Vendor *</h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}
                      name="vendorId"
                      onChange={(event) => {
                        let vendors = vendorsData.filter(e => e.userId == event.target.value);
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          vendors: vendors,
                          vendorId: event.target.value
                        }));
                      }}
                      value={jobObj.vendorId}
                      >
                      <option value="">Select Vendor</option>
                        {vendorsData.map((vendor) => (
                          <option
                            key={vendor.userId}
                            value={vendor.userId}
                          >
                            {vendor.firstName}
                          </option>
                        ))}
                    </select> */}
                  </div>
                </div>
              </div>

              <div className="job-details" style={{ marginTop: "20px", minHeight:"240px" }}>
                <div className="row">
                  <div className="col">
                    <label><h6>Delivery Unit </h6></label>
                    <select 
                      className="form-control-lg-8 drop-down" 
                      style={{ width: "100%", height: "50%" }}  
                      name="delUnitId"
                      value={jobObj.delUnitId}
                      onChange={(event) => {
                        let delUnit = delUnits.filter(e => e.delUnitId == event.target.value)[0];
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          delUnitId: delUnit.delUnitId,
                          deliveryUnit: delUnit.deliveryUnit,
                          vertical: delUnit.vertical,
                          lineOfBusiness: delUnit.lineOfBusiness,
                          accountName: delUnit.accountName,
                        }));
                      }}
                      >
                      <option value="">Select Delivery Unit</option>
                        {delUnits.map((bu) => (
                          <option
                            key={bu.delUnitId}
                            value={bu.delUnitId}
                          >
                            {bu.deliveryUnit}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col">
                    <label><h6>Vertical</h6></label>
                    <input className="form-control editable" disabled="true" 
                    placeholder="Vertical" name="vertical" value={jobObj.vertical}/>
                  </div>
                  <div className="col">
                    <label><h6>Line Of Business</h6></label>
                    <input className="form-control editable"disabled="true" 
                    placeholder="Line Of Business" name="lineOfBusiness" value={jobObj.lineOfBusiness}/>
                  </div>
                </div>

                <br></br>

                <div className="row">
                  <div className="col">
                    <label><h6>Account Name</h6></label>
                    <input className="form-control editable" disabled="true" 
                    placeholder="Account Name" name="accountName" value={jobObj.accountName}/>
                  </div>
                  <div className="col">
                  </div>
                  <div className="col">
                  </div>
                </div>
              </div>

              <div className="comments-remarks" style={{ marginTop: "20px", padding: "20px" }} >
                <div className="row">
                  <label><h6>Remarks</h6></label>
                  <div className="col-10">
                    <textarea
                      rows="3"
                      className="form-control editable "
                      placeholder="Enter Remarks"
                      name="remarks"
                      onChange={(event) => {
                        setJobData((prevJobData) => ({
                          ...prevJobData,
                          remarks: event.target.value,
                        }));
                      }}
                      value={jobObj.remarks}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: '20px', textAlign: 'center', paddingBottom: '5px' }}>
                  <div className="col" style={{ textAlign: 'center' }}>
                    <button
                      type="button"
                      className="btn btn-outline-primary me-2"
                      style={{
                        border: '1px solid #3E67B1',
                        borderRadius: ' 4px',
                        opacity: '1',
                        width: '96px',
                        height: '50px',
                      }}
                      onClick={()=>{
                        navigate(-1);
                      }}
                    >
                      BACK
                    </button>
                    <button
                    onClick={() => {
                      const validation = validateJobData(jobObj);
                      if(!validation.isValid){
                        toast.error(validation.mgs)
                        return;
                      }
                      handleCreateJob(jobObj);
                    }}
                      type="button"
                      className="btn btn-primary"
                      style={{
                        border: '1px solid #3E67B1',
                        borderRadius: ' 4px',
                        opacity: '1',
                        width: '96px',
                        height: '50px',
                        display: (hasAllPermissions || userPermissions.includes('CREATE_JOB')) ? 'inline' : 'none'

                      }}
                    >
                      CREATE
                    </button>
                  </div>
                </div>
              </div>

            </form>
            
            {/* BulkForm */}
            <form ref={formRef2} style={{ marginTop: '54px', display: selectedForm == 'form2' ? 'inline': 'none' }}>
              
              <div className="job-details" style={{ marginTop: "20px" }}>
                
              <div className="row">
                <div className="col"></div>
                <div className="col">
                  <a href="#" onClick={downloadJobsTemplate}>Download Sample Jobs Template</a>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <label>
                    <h6>Upload Job Description Details *</h6>
                  </label>
                  <label
                    for="bulkUpload"
                    style={{
                      height: "50px",
                      width: "368px",
                      background: "#EEF4FF 0% 0% no-repeat padding-box",
                      borderRadius: "4px",
                      opacity: 1,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        width: "368px",
                        height: "50px",
                        background: "#EEF4FF 0% 0% no-repeat padding-box",
                        borderRadius: "4px",
                        opacity: 1,
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <img src="/Images/pdfIcon.svg" style={{paddingLeft: '20px'}} /> 
                      <span style={{paddingLeft: '10px'}}> {"  "} </span>
                      <span style={{ color: "#3E67B1", paddingLeft: '10px', paddingRight: '10px' }}> Browse </span>
                      <span style={{marginLeft: '10px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',  maxWidth: '50%' }}> {bulkCSV?.name}</span>

                    </span>
                    <input
                      style={{ display: "none" }}
                      id="bulkUpload"
                      type="file"
                      name="bulkUpload"
                      accept=".csv" 
                      onChange={handleFileChange}
                    />
                  </label>
                  <span>
                  Only CSV Files Supported with Size lessthan 1 MB
                  </span>
                </div>
                <div className="col">
                </div>
              </div>
              <div className="row" style={{ marginTop: '20px', textAlign: 'center', paddingBottom: '5px' }}>
                  <div className="col" style={{ textAlign: 'center' }}>
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2"
                      style={{
                        border: '1px solid #3E67B1',
                        borderRadius: ' 4px',
                        opacity: '1',
                        width: '96px',
                        height: '50px',
                      }}
                      onClick={async () => {
                        setBulkCSV(null)
                      }}
                    >
                      CLEAR
                    </button>
                    <button
                      onClick={async () => {
                        if (!bulkCSV) {
                          toast.error('CSV File is missing')
                          return;
                        }
                        if(csvData.length == 0) {
                          toast.error(`Please upload valid CSV file.!`);
                          return;
                        }
                        
                        let isValidData = validateBulkJobData(csvData);
                        if (!isValidData.isValid) {
                          console.log("isValidData", isValidData)
                          toast.error(`Missing mandatory fields`);
                        }
                        let isValidValues = validIntegerValues(csvData);
                        if (!isValidValues.isValid) {
                          console.log("isValidValues", isValidValues)
                          toast.error(`Invalid Values found for Integer Fields`);
                        }

                        let bulkJobsData = [];
                        for (let i=0; i< csvData.length; i++){
                          let customerId = parseInt(csvData[i]['customerId']);
                          // let custCoordinatorId = parseInt(csvData[i]['custAdminId']);
                          // let delUnitId = parseInt(csvData[i]['delUnitId']);

                          let customer = customersData.filter(c => c.customerId == customerId)[0];
                          if (!customer) {
                            toast.error(`Customer not found for customerId: ${customerId}`);
                          }

                          let jobObj = {
                            customerId: customerId,
                            custCoordinatorId: 0,
                            vendorId: 0,
                            delUnitId: 0,
                            skillFamily: csvData[i]['skillFamily'],
                            positionsRequired: parseInt(csvData[i]['positionsRequired']),
                            requiredExperience: parseInt(csvData[i]['requiredExperience']),
                            custCoordinatorName: customer.name,
                            custCoordinatorEmailId: customer.emailId,
                            customerCompany: customer.companyName,
                            rateCategory: 'ratesInIndia',
                            rateCard: 'highDemand',
                            isBonusApplicable: false,
                            deliveryUnit: '',
                            vertical: '',
                            lineOfBusiness: '',
                            accountName: '',
                            jobName: csvData[i]['jobName'],
                            jobDescription: csvData[i]['jobDescription'],
                            keyResponsibilities: csvData[i]['keyResponsibilities'],
                            primarySkills: csvData[i]['primarySkills'].replace(/\|/g, ','),
                            secondarySkills: '',
                            interviewDuration: '30 Mins',
                            shiftType: 'Hybrid',
                            shiftTimings: '09:00 - 18:00',
                            country: "IND",
                            sourcing: csvData[i]['sourcing'],
                            vendors: [],
                            inviteSendToCandidates: true,
                            remarks: '',
                          }
                          
                          


                          // let customerCoordinator = coordinatorsData.filter(c => c.userId == custCoordinatorId)[0];
                          // if (!customerCoordinator) {
                            // toast.error(`CustomerCoordinator not found for custAdminId: ${custCoordinatorId}`);
                            // custCoordinatorId = 0;
                          //   custCoordinatorName = 
                          //   custCoordinatorId: coordinator.userId,
                          // custCoordinatorName: coordinator.name,
                          // custCoordinatorEmailId: coordinator.emailId,

                          // }

                          // let delUnit = delUnits.filter(d => d.delUnitId == delUnitId)[0];
                          // if (!delUnit) {
                          //   toast.error(`Delivery Unit not found for delUnitId: ${delUnitId}`);
                          // }

                          bulkJobsData.push(jobObj);
                        }
                        handleUploadJob(bulkJobsData);
                      }}
                      type="button"
                      className="btn btn-primary"
                      style={{
                        border: '1px solid #3E67B1',
                        borderRadius: ' 4px',
                        opacity: '1',
                        width: '196px',
                        height: '50px',
                        display: (bulkCSV && (hasAllPermissions || userPermissions.includes('CREATE_JOB'))) ? 'inline' : 'none'
                      }}
                    >
                      UPLOAD JOBS
                    </button>
                  </div>
                </div>

                
              </div>

            </form>
            
          </div>

        {/* select template modal */}

          <div
            className="modal fade"
            id="exampleModal-1"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{padding:'10px'}}
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <span>
                    <h4>Template Details</h4>
                  </span>
                  <span>
                    <TextField
                      placeholder="Search"
                      size="small"
                      style={{
                        background: "#FCFCFC 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 6px #3E67B133",
                        borderRadius: "4px",
                        opacity: "1",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </span>
                </div>
                <div className="modal-body" style={{margin:'10px'}}>
                    <BootstrapTable
                      hover
                      bootstrap4
                      keyField="jobId"
                      data={filteredJobsData}
                      columns={jobsColumns}
                      pagination={paginationFactory(options)}
                    />
                </div>
               <div className="modal-footer" style={{margin:'10px'}}>
               <div
                className="row"
                style={{ textAlign: "center" }}
              >
                <div className="col">
                  <button
                    data-bs-dismiss="modal"
                    className="btn"
                    style={{
                      width: "96px",
                      height: "50px",
                      border: "1px solid #3E67B1",
                      borderRadius: "4px",
                      opacity: 1,
                    }}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
               </div>
              </div>
            </div>
          </div>
  {/* modal end */}



        </div>

      </>
    );
    
  } else {
    return (
      <>
        <div className="container d-flex justify-content-center align-items-center" style={{marginTop: "100px"}}>
          <div className="card">
            <div className="card-body text-center">
              <h2 className="card-title">
                <i className="fas fa-exclamation-triangle mr-2" style={{paddingRight: "10px"}}></i>
                Access Denied
              </h2>
              <p className="card-text">You do not have the required permissions to access this page.</p>
              <p className="card-text">Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
